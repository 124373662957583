import React, { useState, useEffect } from 'react';
import LayoutDashboard from "../../components/LayoutDashboard";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import FormHelperText from '@mui/joy/FormHelperText';
import Input from '@mui/joy/Input';
import Snackbar from '@mui/joy/Snackbar';
import IconButton from '@mui/joy/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const WpSettings = () => {
    const [wpSettings, setWpSettings] = useState({
        domain: '',
        password: '',
        username: ''
    });

    const [isEditing, setIsEditing] = useState({
        domain: false,
        password: false,
        username: false
    });

    const [errors, setErrors] = useState({
        domain: '',
        password: '',
        username: ''
    });

    const [openSB, setOpenSB] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    useEffect(() => {
        const fetchWpSettings = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/settings/wp`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setWpSettings(data);
                } else {
                    console.error('Ошибка при получении настроек WordPress');
                }
            } catch (error) {
                console.error('Ошибка запроса:', error);
            }
        };

        fetchWpSettings();
    }, []);

    const handleEditClick = (field) => {
        setIsEditing(prev => ({ ...prev, [field]: true }));
    };

    const handleCancelClick = (field) => {
        setIsEditing(prev => ({ ...prev, [field]: false }));
    };

    const handleSaveClick = async (field) => {
        const isValid = validateInput(field, wpSettings[field]);
        if (!isValid) return;

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/settings/wp`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({ [field]: wpSettings[field] })
            });

            if (response.ok) {
                setOpenSB(true);
                setIsEditing(prev => ({ ...prev, [field]: false }));
            } else {
                console.error('Ошибка при обновлении настроек WordPress');
            }
        } catch (error) {
            console.error('Ошибка запроса:', error);
        }
    };

    const validateInput = (field, value) => {
        if (!value.trim()) {
            setErrors(prev => ({ ...prev, [field]: 'Это поле не может быть пустым' }));
            return false;
        }
        setErrors(prev => ({ ...prev, [field]: '' }));
        return true;
    };

    return (
        <LayoutDashboard>
            <Box sx={{
                display: 'flex',
                mb: 1,
                gap: 1,
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: { xs: 'start', sm: 'center' },
                flexWrap: 'wrap',
                justifyContent: 'space-between',
            }}>
                <Typography level="h2" component="h1">
                    Настройки WordPress
                </Typography>
            </Box>

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                overflowX: 'hidden',
                overflowY: 'auto',
                maxHeight: '100%',
            }}>
                {/* Domain */}
                <FormControl sx={{ width: '100%' }} error={!!errors.domain}>
                    <FormLabel>Домен:</FormLabel>
                    {!isEditing.domain ? (
                        <Typography sx={{cursor: 'pointer'}} onClick={() => handleEditClick('domain')}>
                            {wpSettings.domain}
                        </Typography>
                    ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Input
                                sx={{ flexGrow: 1 }}
                                value={wpSettings.domain}
                                onChange={(e) => setWpSettings({...wpSettings, domain: e.target.value})}
                                placeholder="Домен"
                            />
                            <IconButton variant="solid" color="primary" onClick={() => handleSaveClick('domain')}>
                                <CheckCircleIcon />
                            </IconButton>
                            <IconButton variant="solid" color="danger" onClick={() => handleCancelClick('domain')}>
                                <CancelIcon />
                            </IconButton>
                        </Box>
                    )}
                    {errors.domain && <FormHelperText>{errors.domain}</FormHelperText>}
                </FormControl>

                {/* Username */}
                <FormControl sx={{ width: '100%' }} error={!!errors.username}>
                    <FormLabel>Имя пользователя:</FormLabel>
                    {!isEditing.username ? (
                        <Typography sx={{cursor: 'pointer'}} onClick={() => handleEditClick('username')}>
                            {wpSettings.username}
                        </Typography>
                    ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Input
                                sx={{ flexGrow: 1 }}
                                value={wpSettings.username}
                                onChange={(e) => setWpSettings({...wpSettings, username: e.target.value})}
                                placeholder="Имя пользователя"
                            />
                            <IconButton variant="solid" color="primary" onClick={() => handleSaveClick('username')}>
                                <CheckCircleIcon />
                            </IconButton>
                            <IconButton variant="solid" color="danger" onClick={() => handleCancelClick('username')}>
                                <CancelIcon />
                            </IconButton>
                        </Box>
                    )}
                    {errors.username && <FormHelperText>{errors.username}</FormHelperText>}
                </FormControl>

                {/* Password */}
                <FormControl sx={{ width: '100%' }} error={!!errors.password}>
                    <FormLabel>Пароль:</FormLabel>
                    {!isEditing.password ? (
                        <Typography sx={{cursor: 'pointer'}} onClick={() => {
                            setShowPassword(false)
                            handleEditClick('password')
                        }}>
                            {wpSettings.password.replace(/./g, '*')}
                        </Typography>
                    ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Input
                                type={showPassword ? "text" : "password"}
                                sx={{ flexGrow: 1 }}
                                value={wpSettings.password}
                                onChange={(e) => setWpSettings({...wpSettings, password: e.target.value})}
                                placeholder="Пароль"
                                endDecorator={<IconButton variant="plain" onClick={togglePasswordVisibility}>
                                                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>}
                            />
                            <IconButton variant="solid" color="primary" onClick={() => handleSaveClick('password')}>
                                <CheckCircleIcon />
                            </IconButton>
                            <IconButton variant="solid" color="danger" onClick={() => handleCancelClick('password')}>
                                <CancelIcon />
                            </IconButton>
                        </Box>
                    )}
                    {errors.password && <FormHelperText>{errors.password}</FormHelperText>}
                </FormControl>
            </Box>

            <Snackbar
                autoHideDuration={3000}
                variant="soft"
                color="success"
                open={openSB}
                onClose={() => setOpenSB(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                startDecorator={<CheckCircleIcon />}
                endDecorator={
                    <Button onClick={() => setOpenSB(false)} size="sm" variant="soft" color="success">
                        Закрыть
                    </Button>
                }
            >
                Настройки успешно обновлены.
            </Snackbar>
        </LayoutDashboard>
    );
};

export default WpSettings;
