import React, { useEffect, useState } from "react";

import Typography from "@mui/joy/Typography";
import Box from '@mui/joy/Box';
import ChipMT from '@mui/material/Chip';

import Chip from '@mui/joy/Chip';

import { DataGrid,GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector, ruRU } from '@mui/x-data-grid';
import {NavLink} from 'react-router-dom';

import LayoutDashboard from "../../components/LayoutDashboard";
import { Experimental_CssVarsProvider as MUICssVarsProvider } from '@mui/material/styles';

import Button from '@mui/joy/Button';

import IconButton from '@mui/material/IconButton';

import Table from '@mui/joy/Table';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Sheet from "@mui/joy/Sheet";
import Switch from '@mui/joy/Switch';


import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';
import CardContent from "@mui/joy/CardContent";
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import DialogTitle from '@mui/joy/DialogTitle';
import ModalOverflow from '@mui/joy/ModalOverflow';

import DeleteForever from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import FormHelperText from '@mui/joy/FormHelperText';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Radio from '@mui/joy/Radio';

import Divider from '@mui/joy/Divider';

import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import Stack from '@mui/joy/Stack';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';

import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import RttIcon from '@mui/icons-material/Rtt';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import TaxiAlertIcon from '@mui/icons-material/TaxiAlert';

import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';




import ReactMarkdown from 'react-markdown';
import PlaylistAddCheckCircleRoundedIcon from "@mui/icons-material/PlaylistAddCheckCircleRounded";
import Snackbar from "@mui/joy/Snackbar";


const AtWork = () => {

    const [articles, setArticles] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [articleData, setArticleData] = useState(null);



    const [page, setPage] = useState(0); // Текущая страница
    const [pageSize, setPageSize] = useState(100); // Элементов на странице
    const [total, setTotal] = useState(0); // Общее количество статей

    const [openDialog, setOpenDialog] = useState(false);
    const [openDialogOne, setOpenDialogOne] = useState(false);

    const [currentArticleToDelete, setCurrentArticleToDelete] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);

    const [isLoadingData, setIsLoadingData] = useState(false);

    const [statisticsTextData, setStatisticsTextData] = useState(null);
    const [statisticsImagesData, setStatisticsImagesData] = useState(null);
    const [isLoadingStatistics, setIsLoadingStatistics] = useState(true);
    const [errorLogs, setErrorLogs] = useState([]);
    const [isLoadingErrorLogs, setIsLoadingErrorLogs] = useState(false);

    const [isAddProcess,setIsAddProcess]= useState(false);
    const [isOpenModalAdd,setIsOpenModalAdd]= useState(false);
    const [articleUrl, setArticleUrl] = useState('');
    const [addError, setAddError] = useState('');
    const [addComplete, setAddComplete] = useState(false);

    const [openQueryModal, setOpenQueryModal] = useState(false);
    const [openRedoModal, setOpenRedoModal] = useState(false);
    const [openSB, setOpenSB] = React.useState(false);

    const [redo, setRedo] = useState({
        status:true,
        instructions_articles: '',
        instructions_add_suggest: '',
        markdown:false,
        instructions_markdown: "в стиле Markdown, обеспечив четкость и эстетичность представления текста (ОБЯЗАТЕЛЬНО форматируй новую статью используй markdown для красивого оформления)",
        text_orign:0
    });
    const [defaultRedo, setDefaultRedo] = useState({
        status:true,
        instructions_articles: '',
        instructions_add_suggest: '',
        markdown:false,
        instructions_markdown: "в стиле Markdown, обеспечив четкость и эстетичность представления текста (ОБЯЗАТЕЛЬНО форматируй новую статью используй markdown для красивого оформления)",
        text_orign:0
    });


    const handleSubmitRedo = async (redoIncluded) => {
        const requestData = redoIncluded ? { redo: redo } : {};

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/articles/update_redo/${articleData._id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestData)
            });

            if (response.ok) {
                // Обработка успешного запроса
                console.log(`Статья отправлена на доработку ${redoIncluded ? "с пользовательскими инструкциями" : "со стандартными инструкциями"}`);
                setOpenModal(false)
                setOpenRedoModal(false);
                setOpenSB(true);
                loadArticles();
                // Обновите данные о статье или перезагрузите список статей, если это необходимо
            } else {
                // Обработка ошибок
                console.error('Ошибка при отправке статьи на доработку');
            }
        } catch (error) {
            console.error('Ошибка запроса:', error);
        }
    };


    useEffect(() => {
        const fetchRedo = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/settings/gpt`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setRedo(prevRedo => ({
                        ...prevRedo,
                        instructions_articles: data.instructions_articles,
                        instructions_add_suggest: data.instructions_add_suggest,
                        markdown:data.markdown
                    }));
                    setDefaultRedo(prevDefaultRedo => ({
                        ...prevDefaultRedo,
                        instructions_articles: data.instructions_articles,
                        instructions_add_suggest: data.instructions_add_suggest,
                        markdown:data.markdown
                    }));
                } else {
                    console.error('Ошибка при получении настроек GPT');
                }
            } catch (error) {
                console.error('Ошибка запроса:', error);
            }
        };

        fetchRedo();
    }, []);


    const resetRedoToDefault = () => {
        if (defaultRedo) {
            setRedo(defaultRedo);
        }
    };

    // Функция для обработки изменения в поле ввода
    const handleInputChange = (e) => {
        setArticleUrl(e.target.value);
        setAddError(''); // Очистить ошибку при каждом изменении
    };
    // Функция для обработки нажатия на кнопку "добавить"
    const handleAddArticle = async () => {
        const result = await addArticle(articleUrl);
        if (result === "OK") {
            setAddComplete(true);
            // Успешное добавление
            setAddError('');
            await loadArticles(); // Обновить данные в DataGrid
            // Показать сообщение о успешном добавлении
        } else {
            // Ошибка
            setAddError(result);
        }
    };

    // Функция для сброса состояний при закрытии модального окна
    const handleCloseModal = () => {
        setIsOpenModalAdd(false);
        setIsAddProcess(false);
        setAddComplete(false);
        setArticleUrl('');
        setAddError('');
    };

    const formatDate = (rawDate) => {
        return new Date(rawDate).toLocaleString('ru-RU', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        });
    };

    // добавление статьи
    const addArticle = async (url) => {
        try {
            new URL(url);
        } catch (error) {
            return "Некорректный URL";
        }

        const token = localStorage.getItem('token');
        if (token) {
            setIsAddProcess(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/articles/add`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ url, type: "non", status: 0 }) // Подставьте нужные значения
                });

                if (response.ok) {
                    setIsAddProcess(false);
                    return "OK";
                } else {
                    const errorData = await response.json();
                    setIsAddProcess(false);
                    return errorData.message || "Ошибка запроса";
                }
            } catch (error) {
                setIsAddProcess(false);
                console.error('Ошибка при добавлении статьи', error);
                return "Ошибка запроса";
            }
        }
        return "Токен не найден";
    };

    // Функция для обработки клика по чипам suggest_google
    const toggleGoogleSuggestAvailable = async (index) => {
        let updatedGoogleSuggests = [...articleData.data.content.google_suggest];
        updatedGoogleSuggests[index].available = !updatedGoogleSuggests[index].available;

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/articles/update_suggest/${articleData._id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ suggestType: "google", suggests: updatedGoogleSuggests })
            });

            if (response.ok) {
                // Обновляем данные в состоянии
                setArticleData({
                    ...articleData,
                    data: {
                        ...articleData.data,
                        content: {
                            ...articleData.data.content,
                            google_suggest: updatedGoogleSuggests
                        }
                    }
                });
            }
        } catch (error) {
            console.error('Ошибка при запросе к серверу', error);
        }
    };

    const toggleYandexSuggestAvailable = async (index) => {
        let updatedSuggests = [...articleData.data.content.yandex_suggest];
        updatedSuggests[index].available = !updatedSuggests[index].available;

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/articles/update_suggest/${articleData._id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ suggestType: "yandex", suggests: updatedSuggests })
            });

            if (response.ok) {
                // Обновляем данные в состоянии
                setArticleData({ ...articleData, data: { ...articleData.data, content: { ...articleData.data.content, yandex_suggest: updatedSuggests } } });
            }
        } catch (error) {
            console.error('Ошибка при запросе к серверу', error);
        }
    };


    const handleDeleteClick = (article) => {
        setCurrentArticleToDelete(article);
        setOpenDialogOne(true);
    };

    const handleDeleteArticle = async () => {
        console.log("Удаление статьи с ID:", currentArticleToDelete.id);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/articles/delete`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ ids: [currentArticleToDelete.id] })
            });

            const data = await response.json();
            if (response.ok) {
                // Удаляем из списка статей только те, которые были успешно удалены на сервере
                await loadArticles();
            } else {
                // Обработка ошибок
                console.error('Ошибка при удалении статьи', data.message);
            }
        } catch (error) {
            console.error('Ошибка при запросе к серверу', error);
        }
        setOpenDialogOne(false);
    };

    const handleDeleteArticles = async () => {
        const idsToDelete = selectedRows.map(row => row.id);
        try {
            setOpenDialog(false); // Закрываем диалоговое окно перед отправкой запроса
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/articles/delete`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ ids: idsToDelete })
            });

            const data = await response.json();
            if (response.ok) {
                await loadArticles();
            } else {
                console.error('Ошибка при удалении статей', data.message);
            }
        } catch (error) {
            console.error('Ошибка при запросе к серверу', error);
        } finally {
            setSelectedRows([]); // Очистить выбранные строки
        }
    };



    function AlertDialogModalOne({ open, onClose, onConfirm, articleId, articleTitle }) {
        return (
            <Modal open={open} onClose={onClose}>
                <ModalDialog variant="outlined" role="alertdialog">
                    <DialogTitle>
                        <WarningRoundedIcon />
                        Статья {articleId}
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        Вы действительно хотите удалить статью "{articleTitle}"?
                    </DialogContent>
                    <DialogActions>
                        <Button variant="solid" color="danger" onClick={onConfirm}>
                            Удалить
                        </Button>
                        <Button variant="plain" color="neutral" onClick={onClose}>
                            Отмена
                        </Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>
        );
    }

    function AlertDialogModalPlural({ open, onClose, onConfirm, selectedArticles }) {
        const [isDeleting, setIsDeleting] = useState(false);

        const handleConfirm = async () => {
            setIsDeleting(true);
            await onConfirm();
            setIsDeleting(false);
            onClose();
        };

        return (
            <Modal open={open} onClose={onClose}>
                <ModalDialog variant="outlined" role="alertdialog">
                    <DialogTitle>
                        <WarningRoundedIcon />
                        Удаление статей
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        Вы действительно хотите удалить следующие статьи?
                        <ul>
                            {selectedArticles.map(article => (
                                <li key={article.id}>{article.title} (ID: {article.id})</li>
                            ))}
                        </ul>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="solid" color="danger" onClick={handleConfirm} loading={isDeleting}>
                            Удалить
                        </Button>
                        <Button variant="plain" color="neutral" onClick={onClose}>
                            Отмена
                        </Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>
        );
    }



    const fetchArticleById = async (id) => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/articles/getid/${id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const data = await response.json();
                if (response.ok) {
                    setArticleData(data);
                    setOpenModal(true);
                }
            } catch (error) {
                console.error('Ошибка при получении данных статьи', error);
            }
        }
    };


    const columns = [
        {
            field: 'index_fp',
            headerName: '#',
            width: 90,
        },
        {
            field: 'url',
            headerName: 'Url',
            width: 150,
            editable: false,
            renderCell: (params) => (
                <a href={params.value} target="_blank" rel="noopener noreferrer">
                    {params.value}
                </a>
            ),
        },
        {
            field: 'title',
            headerName: 'Заголовок',
            width: 200,
            editable: false,
        },
        {
            field: 'type',
            headerName: 'Тип',
            width: 150,
            editable: false,
            renderCell: (params) => {
                let color;
                let label;
                switch (params.value) {
                    case "category":
                        label = "категория";
                        color = "warning";
                        break;
                    case "article":
                        label = "статья";
                        color = "success";
                        break;
                    default:
                        color = "default";
                        label = "Неизвестно";
                }
                return <ChipMT variant="outlined" color={color} label={label}  size="small"></ChipMT>;
            },
        },
        {
            field: 'status',
            headerName: 'Статус',
            flex: 1,
            renderCell: (params) => {
                if (params.value <= 4) {
                    return (
                        <>
                            <ChipMT variant={params.value > 0 ? "" : "outlined"} color={params.value > 0 ? "success" : "warning"} label="в работе" size="small" />
                            <ChipMT variant={params.value > 1 ? "" : "outlined"} color={params.value > 1 ? "success" : "warning"} label="скраппинг" size="small" />
                            <ChipMT variant={params.value > 2 ? "" : "outlined"} color={params.value > 2 ? "success" : "warning"} label="chatGPT" size="small" />
                            <ChipMT variant={params.value > 3 ? "" : "outlined"} color={params.value > 3 ? "success" : "warning"} label="публикация" size="small" />
                        </>
                    );
                } else {
                    let color, label;
                    switch (params.value) {
                        case 5:
                            color = "warning";
                            label = "ошибка";
                            break;
                        case 6:
                            color = "error";
                            label = "ошибка";
                            break;
                        case 10:
                            color = "error";
                            label = "отсутсвует паук";
                            break;
                        case 11:
                            color = "error";
                            label = "отсутсвует контент";
                            break;
                        case 13:
                            color = "error";
                            label = "ошибка";
                            break;
                        default:
                            color = "default";
                            label = "Неизвестно";
                    }
                    return <ChipMT color={color} label={label} size="small" />;
                }
            },
            editable: false,
        },
        {
            field: 'date_add',
            headerName: 'Дата изменения',
            type: 'dateTime',
            width: 200,
            valueGetter: (params) => new Date(params.value),
            editable: false,
        },
        {
            field: 'actions',
            headerName: 'Действия',
            width: 100,
            renderCell: (params) => (
                <>
                    <IconButton color="success" onClick={() => fetchArticleById(params.row._id)}>
                        <EditIcon/>
                    </IconButton>
                    <IconButton color="error" onClick={() => handleDeleteClick(params.row)}>
                        <DeleteForever/>
                    </IconButton>
                </>
            ),
        },

    ];
    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
                {selectedRows.length > 0 && (
                    <IconButton
                        color="error"
                        onClick={() => setOpenDialog(true)}
                    >
                        <DeleteForever/>
                    </IconButton>
                )}
            </GridToolbarContainer>
        );
    }




    const fetchArticles = async (page, limit) => {
        const token = localStorage.getItem('token'); // Получение токена из localStorage
        if (token) {
            try {
                // Формирование URL с учетом пагинации и фильтра
                const url = `${process.env.REACT_APP_API_URL}/api/articles/get?status=true&range=0,20&page=${page}&limit=${limit}`;
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                const data = await response.json();
                if (response.ok) {
                    // Преобразование данных для таблицы
                    // Необходимо убедиться, что данные соответствуют ожидаемому формату
                    return {
                        data: data.data.map((article, index) => ({
                            ...article,
                            id: article._id,
                            index_fp: (page - 1) * limit + index + 1 // Вычисление порядкового номера
                        })),
                        total: data.totalItems
                    };
                } else {
                    // Обработка ошибок, например, отображение сообщения об ошибке
                    console.error('Ошибка при получении статей', data.message);
                }
            } catch (error) {
                console.error('Ошибка при запросе к серверу', error);
            }
        }
        return { data: [], total: 0 };
    };

    const loadArticles = async () => {
        setIsLoadingData(true);
        const result = await fetchArticles(page + 1, pageSize);
        setArticles(result.data);
        setTotal(result.total);
        setIsLoadingData(false);
    };

    useEffect(() => {
        setArticles([]);

        loadArticles();
    }, [page, pageSize]);


    const renderChipByType = () => {
        if (!articleData) return null;
        if (articleData.type === 'category') {
            return <Chip color="warning" size="md"
                         variant="outlined" >категория</Chip>;
        } else if (articleData.type === 'article') {
            return <Chip color="primary" size="md"
                         variant="outlined">статья</Chip>;
        }
    };


    const fetchStatistics = async (articleId) => {
        setIsLoadingStatistics(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/statistics/article/${articleId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (response.ok) {
                //setstatisticsImagesData setStatisticsTextData
                const statistics = await response.json();

                // Фильтрация статистики для обработки только элементов с type == "text_generation" и "text_generation_md"
                const filteredStatisticsText = statistics.filter(stat =>
                    stat.type === "text_generation" || stat.type === "text_generation_md"
                );

                let lastStatisticsText = false;

                // Сортировка отфильтрованной статистики
                const sortedStatisticsText = filteredStatisticsText.sort((a, b) => new Date(b.date) - new Date(a.date));

                if (sortedStatisticsText.length > 0) {
                    lastStatisticsText = sortedStatisticsText[0];
                }

                // Расчет общих статистических данных
                const totalStatsText = sortedStatisticsText.reduce((acc, stat) => {
                    acc.completion_tokens += stat.completion_tokens;
                    acc.prompt_tokens += stat.prompt_tokens;
                    acc.total_tokens += stat.total_tokens;
                    return acc;
                }, { completion_tokens: 0, prompt_tokens: 0, total_tokens: 0 });

                setStatisticsTextData({
                    statistics: filteredStatisticsText,
                    sortedStatistics: sortedStatisticsText,
                    lastStatistics: lastStatisticsText,
                    totalStats: totalStatsText
                });


                // Фильтрация статистики для обработки только элементов с type == "image_generation"
                const filteredStatisticsImages = statistics.filter(stat => stat.type === "image_generation");

                let lastStatisticsImages = false;

                // Сортировка отфильтрованной статистики
                const sortedStatisticsImages = filteredStatisticsImages.sort((a, b) => new Date(b.date) - new Date(a.date));


                if (sortedStatisticsImages.length > 0) {
                    lastStatisticsImages = sortedStatisticsImages[0];
                }

                // Расчет общих статистических данных
                const totalStatsImages = sortedStatisticsImages.reduce((acc, stat) => {
                    acc.n += stat.n;
                    return acc;
                }, { n: 0});


                setStatisticsImagesData({
                    statistics: filteredStatisticsImages,
                    sortedStatistics: sortedStatisticsImages,
                    lastStatistics: lastStatisticsImages,
                    totalStats: totalStatsImages
                });

                setIsLoadingStatistics(false);
            }
        } catch (error) {
            setIsLoadingStatistics(false);
            return false;
        }
    };

    const fetchErrorLogs = async (articleId) => {
        setIsLoadingErrorLogs(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/errorlogs/${articleId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (response.ok) {
                const logs = await response.json();
                setErrorLogs(logs);
            } else {
                console.error('Ошибка при получении логов ошибок');
            }
        } catch (error) {
            console.error('Ошибка запроса логов ошибок', error);
        } finally {
            setIsLoadingErrorLogs(false);
        }
    };


    useEffect(() => {
        if (articleData && articleData._id) {
            fetchStatistics(articleData._id);
            fetchErrorLogs(articleData._id);
        }
    }, [articleData]); // Вызов fetchStatistics только при изменении articleData

    const renderModalContent = () => {
        if (!articleData) return null;
        let images = articleData.data?.content?.images || [];
        let categories = articleData.data?.content?.categories || [];
        let suggest_yandex = articleData.data?.content?.yandex_suggest || [];
        let suggest_google = articleData.data?.content?.google_suggest || [];
        let textContent = articleData.data?.content?.text || null;

        let article_status = articleData.status || null;
        let title = articleData.data?.rewrite?.title || null;
        let textRewrite = articleData.data?.rewrite?.text || null;
        let senseRewrite = articleData.data?.rewrite?.sense || null;
        let tagsRewrite = articleData.data?.rewrite?.tags || null;

        let rew_images = articleData.data?.rewrite?.images || [];



        return  <Tabs aria-label="Basic tabs" defaultValue={article_status>2 ? 1 : 0}>
            <TabList>
                <Tab>Оригинал</Tab>
                {article_status>2 && (
                    <>
                        <Tab>Рерайт</Tab>
                        <Tab>Статистика</Tab>
                    </>
                )}
            </TabList>
            <TabPanel value={0}>
                <>
                    <Card sx={{mb:1}}>
                        <CardContent>
                            <Typography>{`Дата изменения: ${new Date(articleData.date_add).toLocaleString()}`}</Typography>
                        </CardContent>
                    </Card>


                    {textContent && (
                        <Card sx={{mb:1}}>
                            <CardContent>
                                <Typography level="h3">Оригинальный текст:</Typography>
                                {textContent}
                            </CardContent>
                        </Card>
                    )}

                    {categories.length > 0 && (
                        <Card sx={{mb:1}}>
                            <CardContent>
                                <Typography level="h3">Категории:</Typography>
                                {categories.map((item) => (
                                    <Chip key={item.url} slotProps={{ action: { component: 'a', href: item.url, target: '_blank', rel: 'noopener noreferrer' } }} >
                                        {item.title}
                                    </Chip>
                                ))}
                            </CardContent>
                        </Card>
                    )}

                    {images.length > 0 && (
                        <Card sx={{mb:1}}>
                            <CardContent>
                                <Typography level="h3">Картинки:</Typography>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: 1,
                                        py: 1,
                                        overflow: 'auto',
                                        width: '100%',
                                        scrollSnapType: 'x mandatory',
                                        '& > *': {
                                            scrollSnapAlign: 'center',
                                        },
                                        '::-webkit-scrollbar': { display: 'none' },
                                    }}
                                >
                                    {images.map((item) => (
                                        <Card orientation="horizontal" size="sm" key={item} variant="outlined">
                                            <AspectRatio ratio="1" sx={{ minWidth: 200 }}>
                                                <img
                                                    srcSet={`${process.env.REACT_APP_API_STORAGE_URL}/images/${item}?h=120&fit=crop&auto=format&dpr=2 2x`}
                                                    src={`${process.env.REACT_APP_API_STORAGE_URL}/images/${item}?h=120&fit=crop&auto=format`}
                                                />
                                            </AspectRatio>
                                        </Card>
                                    ))}
                                </Box>
                            </CardContent>
                        </Card>
                    )}

                    {suggest_yandex.length > 0 && (
                        <Card sx={{mb:1}}>
                            <CardContent>
                                <Typography level="h3">Подсказки Яндекса:</Typography>
                                <Box>
                                    {suggest_yandex.map((item, index) => (
                                        <Chip
                                            key={index}
                                            color={item.available ? "success" : "danger"}
                                            variant={item.available ? "solid" : "outlined"}
                                            sx={{mr:1,mb:1}}
                                            onClick={() => toggleYandexSuggestAvailable(index)}
                                            disabled={article_status>2 ? true : false}
                                        >
                                            {item.suggest}
                                        </Chip>
                                    ))}

                                </Box>
                            </CardContent>
                        </Card>
                    )}



                    {suggest_google.length > 0 && (
                        <Card sx={{mb:1}}>
                            <CardContent>
                                <Typography level="h3">Подсказки Google:</Typography>
                                <Box>
                                    {suggest_google.map((item, index) => (
                                        <Chip
                                            key={index}
                                            color={item.available ? "success" : "danger"}
                                            variant={item.available ? "solid" : "outlined"}
                                            sx={{mr:1,mb:1}}
                                            onClick={() => toggleGoogleSuggestAvailable(index)}
                                            disabled={article_status>2 ? true : false}
                                        >
                                            {item.suggest}
                                        </Chip>
                                    ))}
                                </Box>
                            </CardContent>
                        </Card>
                    )}
                </>
            </TabPanel>
            {article_status>2 && (
            <>
                <TabPanel value={1} >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            mb:1
                        }}
                    >
                        <Button variant="outlined" color="neutral" onClick={() => setOpenRedoModal(true)}>
                            Отправить на доработку
                        </Button>
                    </Box>
                    <Card sx={{mb:1}}>
                        <CardContent>
                            <Typography level="h2">{title}</Typography>
                            <ReactMarkdown>{textRewrite}</ReactMarkdown>
                        </CardContent>
                    </Card>

                    {rew_images.length > 0 && (
                        <Card sx={{mb:1}}>
                            <CardContent>
                                <Typography level="h3">Картинки:</Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: 1,
                                        py: 1,
                                        overflow: 'auto',
                                        width: '100%',
                                        scrollSnapType: 'x mandatory',
                                        '& > *': {
                                            scrollSnapAlign: 'center',
                                        },
                                        '::-webkit-scrollbar': { display: 'none' },
                                    }}
                                >
                                    {rew_images.map((item) => (
                                        <Card orientation="horizontal" size="sm" key={item} variant="outlined">
                                            <AspectRatio ratio="1" sx={{ minWidth: 200 }}>
                                                <img
                                                    srcSet={`${process.env.REACT_APP_API_STORAGE_URL}/img/${item}?h=120&fit=crop&auto=format&dpr=2 2x`}
                                                    src={`${process.env.REACT_APP_API_STORAGE_URL}/img/${item}?h=120&fit=crop&auto=format`}
                                                />
                                            </AspectRatio>
                                        </Card>
                                    ))}
                                </Box>
                            </CardContent>
                        </Card>
                    )}
                    <Card sx={{mb:1}}>
                        <CardContent>
                            <Typography level="h3">Смысл:</Typography> {/*senseRewrite tagsRewrite*/}
                            {senseRewrite}
                        </CardContent>
                    </Card>
                    <Card sx={{mb:1}}>
                        <CardContent>
                            <Typography level="h3">Теги:</Typography>
                            <Box>
                                {tagsRewrite && (tagsRewrite.map((item, index) => (
                                    <Chip
                                        key={index}
                                        color={item.available ? "success" : "danger"}
                                        variant={item.available ? "solid" : "outlined"}
                                        sx={{mr:1,mb:1}}
                                    >
                                        {item}
                                    </Chip>
                                )))}
                            </Box>
                        </CardContent>
                    </Card>
                    <Modal
                        open={openRedoModal}
                        onClose={() => {
                            setOpenRedoModal(false);
                            resetRedoToDefault(); // Сброс значений при закрытии
                        }}
                        size="lg"
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex:15000,
                        }}
                    >
                        <ModalDialog>
                            <DialogTitle>Дорабать статью</DialogTitle>
                            <ModalClose variant="plain" sx={{ m: 1 }} />
                            <DialogContent>отправить в ChatGPT с новыми инструкциями</DialogContent>
                                <Stack spacing={2}>
                                    <FormControl>
                                        <FormLabel>Инструкция для написания статьи:</FormLabel>
                                        <Input
                                            value={redo.instructions_articles}
                                            onChange={(e) => setRedo({...redo, instructions_articles: e.target.value})}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Инструкция для добавлений подсказок поисковых систем:</FormLabel>
                                        <Input
                                            value={redo.instructions_add_suggest}
                                            onChange={(e) => setRedo({...redo, instructions_add_suggest: e.target.value})}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <Typography component="label"  endDecorator={<Switch checked={redo.markdown} onChange={(e) => setRedo({...redo, markdown: e.target.checked})} sx={{ ml: 1 }} />}>
                                            Использовать MarkDown
                                        </Typography>
                                    </FormControl>
                                    {redo.markdown && <FormControl>
                                        <FormLabel>Инструкции для создания разметки MarkDown</FormLabel>
                                        <Input
                                            value={redo.instructions_markdown}
                                            onChange={(e) => setRedo({...redo, instructions_markdown: e.target.value})}
                                        />
                                    </FormControl>
                                    }

                                    <FormControl>
                                        <FormLabel>Использовать текст статьи</FormLabel>
                                        <Box sx={{ display: 'flex', gap: 2 }}>
                                            <Radio
                                                checked={redo.text_orign === 0}
                                                onChange={() => setRedo({...redo, text_orign: 0})}
                                                value={0}
                                                name="radio-buttons"
                                                slotProps={{ input: { 'aria-label': 'A' } }}
                                                label="оригинала"
                                            />
                                            <Radio
                                                checked={redo.text_orign === 1}
                                                onChange={() => setRedo({...redo, text_orign: 1})}
                                                value={1}
                                                name="radio-buttons"
                                                slotProps={{ input: { 'aria-label': 'B' } }}
                                                label="реврайта"
                                            />
                                        </Box>
                                    </FormControl>
                                    <Button onClick={() => handleSubmitRedo(true)}>Отправить на доработку</Button>
                                    <Button onClick={() => handleSubmitRedo(false)}>Со стандартными инструкциями</Button>
                                </Stack>
                        </ModalDialog>
                    </Modal>
                </TabPanel>

                <TabPanel value={2} >
                    {isLoadingStatistics ? (
                        <Typography>Загрузка статистики...</Typography>
                    ) : (
                        <>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: 2,
                                    flexWrap: 'wrap'
                                }}
                            >
                                <Card>
                                    <CardContent>
                                        <Typography level="h4">Запрос текста:</Typography>
                                        {statisticsTextData.lastStatistics ? (
                                            <List aria-labelledby="last-stats">
                                                <ListItem>
                                                    <ListItemDecorator>📅</ListItemDecorator>{formatDate(statisticsTextData.lastStatistics.date)}
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemDecorator>💼</ListItemDecorator>Модель: {statisticsTextData.lastStatistics.model}
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemDecorator>⏱️</ListItemDecorator>Время запроса: {statisticsTextData.lastStatistics.time.toFixed(2)} сек
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemDecorator>🎫</ListItemDecorator>Токены запроса: {statisticsTextData.lastStatistics.prompt_tokens}
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemDecorator>🎟️</ListItemDecorator>Токены ответа: {statisticsTextData.lastStatistics.completion_tokens}
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemDecorator>📑</ListItemDecorator>
                                                    <Button variant="outlined" color="neutral" onClick={() => setOpenQueryModal(true)}>
                                                        Посмотреть запрос
                                                    </Button>
                                                </ListItem>
                                                <Modal
                                                    aria-labelledby="modal-title"
                                                    aria-describedby="modal-desc"
                                                    open={openQueryModal}
                                                    onClose={() => setOpenQueryModal(false)}
                                                    size="lg"
                                                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',zIndex:15000 }}
                                                >
                                                    <Sheet
                                                        variant="outlined"
                                                        sx={{
                                                            maxWidth: 500,
                                                            borderRadius: 'md',
                                                            p: 3,
                                                            boxShadow: 'lg',
                                                        }}
                                                    >
                                                        <ModalClose variant="plain" sx={{ m: 1 }} />
                                                        <Typography
                                                            component="h2"
                                                            id="modal-title"
                                                            level="h4"
                                                            textColor="inherit"
                                                            fontWeight="lg"
                                                            mb={1}
                                                        >
                                                            Запрос к ChatGPT
                                                        </Typography>
                                                        <Typography id="modal-desc" textColor="text.tertiary">
                                                            {statisticsTextData.lastStatistics.query}
                                                        </Typography>
                                                    </Sheet>
                                                </Modal>
                                            </List>
                                        ) : (
                                            <Typography color="danger"><TaxiAlertIcon/> отсутствует</Typography>
                                            )}
                                    </CardContent>
                                </Card>
                                <Card>
                                    <CardContent>
                                        <Typography level="h4">Запрос картинки:</Typography>
                                        {statisticsImagesData.lastStatistics ? (
                                            <List aria-labelledby="last-stats">
                                                <ListItem>
                                                    <ListItemDecorator>📅</ListItemDecorator>{formatDate(statisticsImagesData.lastStatistics.date)}
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemDecorator>💼</ListItemDecorator>Модель: {statisticsImagesData.lastStatistics.model}
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemDecorator>⏱️</ListItemDecorator>Время запроса: {statisticsImagesData.lastStatistics.time.toFixed(2)} сек
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemDecorator>📷</ListItemDecorator>Размер: {statisticsImagesData.lastStatistics.size}
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemDecorator>🖇️</ListItemDecorator>Количество: {statisticsImagesData.lastStatistics.n}
                                                </ListItem>
                                            </List>
                                        ) : (
                                            <Typography color="danger"><TaxiAlertIcon/> отсутствует</Typography>
                                        )}

                                    </CardContent>
                                </Card>
                            </Box>

                            <Card sx={{mb:1,mt:2}}>
                                <CardContent>
                                    <Typography level="h4">ChatGPT:</Typography>
                                    {statisticsTextData.sortedStatistics && statisticsTextData.sortedStatistics.length > 0 ? (
                                        <Table aria-label="basic table" stripe="odd">
                                        <thead>
                                        <tr>
                                            <th style={{ width: '5%' }}>№</th>
                                            <th>Дата</th>
                                            <th>Модель</th>
                                            <th>Тип</th>
                                            <th>Время запроса</th>
                                            <th>Токены запроса</th>
                                            <th>Токены ответа</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {statisticsTextData.sortedStatistics.map((stat, index) => (
                                            <tr key={stat._id}>
                                                <td>{index + 1}</td>
                                                <td>{new Date(stat.date).toLocaleString()}</td>
                                                <td>{stat.model}</td>

                                                <td>{stat.type === "text_generation_md" ?
                                                    <IntegrationInstructionsIcon/>
                                                    :
                                                    <RttIcon />}
                                                </td>
                                                <td>{stat.time.toFixed(2)}</td>
                                                <td>{stat.prompt_tokens}</td>
                                                <td>{stat.completion_tokens}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <th scope="row" colSpan={2}>Итого</th>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>{statisticsTextData.totalStats.prompt_tokens}</td>
                                            <td>{statisticsTextData.totalStats.completion_tokens}</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <th scope="row">Всего</th>
                                            <td>{statisticsTextData.totalStats.total_tokens}</td>
                                        </tr>
                                        </tfoot>
                                    </Table>
                                    ) : (
                                        <Typography color="danger"><TaxiAlertIcon/> отсутствует</Typography>
                                    )}
                                </CardContent>
                            </Card>

                            <Card sx={{mb:1,mt:2}}>
                                <CardContent>
                                    <Typography level="h4">Dall-E:</Typography>
                                    {statisticsImagesData.sortedStatistics && statisticsImagesData.sortedStatistics.length > 0 ? (
                                    <Table aria-label="basic table" stripe="odd">
                                        <thead>
                                        <tr>
                                            <th style={{ width: '5%' }}>№</th>
                                            <th>Дата</th>
                                            <th>Модель</th>
                                            <th>Время запроса</th>
                                            <th>Размер</th>
                                            <th>Количество</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {statisticsImagesData.sortedStatistics.map((stat, index) => (
                                            <tr key={stat._id}>
                                                <td>{index + 1}</td>
                                                <td>{new Date(stat.date).toLocaleString()}</td>
                                                <td>{stat.model}</td>
                                                <td>{stat.time.toFixed(2)}</td>
                                                <td>{stat.size}</td>
                                                <td>{stat.n}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <th scope="row">Всего</th>
                                            <td>{statisticsImagesData.totalStats.n}</td>
                                        </tr>
                                        </tfoot>
                                    </Table>
                                    ) : (
                                        <Typography color="danger"><TaxiAlertIcon/> отсутствует</Typography>
                                    )}
                                </CardContent>
                            </Card>
                        </>
                    )}

                    {isLoadingErrorLogs ? (
                        <Typography>Загрузка логов ошибок...</Typography>
                    ) : (
                        <Box sx={{ mt: 2 }}>
                            <Typography level="h4">Логи ошибок:</Typography>
                            {errorLogs.length > 0 ? (
                                <List>
                                    {errorLogs.map(log => (
                                        <ListItem key={log._id}>

                                                {formatDate(log.timestamp)} - {log.message}

                                        </ListItem>
                                    ))}
                                </List>
                            ) : (
                                <Typography>Логи ошибок отсутствуют</Typography>
                            )}
                        </Box>
                    )}
                </TabPanel>
            </>
            )}
        </Tabs>

        // Другая логика для разных типов статей может быть добавлена здесь
    };

    return (
        <>
            <LayoutDashboard>

            <Box
                sx={{
                    display: 'flex',
                    mb: 1,
                    gap: 1,
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: { xs: 'start', sm: 'center' },
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                }}
            >
                <Typography level="h2" component="h1">
                    В работе
                </Typography>

                <Box>
                    <Button
                        sx={{mr:1}}
                        color="primary"
                        size="sm"
                        onClick={() => setIsOpenModalAdd(true)}
                    >
                        Добавить
                    </Button>
                    <NavLink
                        to={'/atwork/add'}
                    >
                        <Button
                            color="primary"
                            size="sm"
                        >
                            Добавить список
                        </Button>

                    </NavLink>
                </Box>
            </Box>
            <MUICssVarsProvider>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '95%'
                }}>
                    <DataGrid
                        localeText={
                            // @ts-ignore
                            ruRU.components.MuiDataGrid.defaultProps.localeText
                        }
                        rows={articles}
                        columns={columns}
                        slots={{ toolbar: CustomToolbar }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                            },
                        }}
                        page={page}
                        pageSize={pageSize}
                        pageSizeOptions={[10,25,50,100]}
                        onPaginationModelChange={(newModel) => {
                            setPage(newModel.page);
                            setPageSize(newModel.pageSize);
                        }}

                        onRowSelectionModelChange={(ids) => {
                            const selectedIDs = new Set(ids);
                            const selectedRowData = articles.filter((row) =>
                                selectedIDs.has(row.id)
                            );
                            setSelectedRows(selectedRowData);
                        }}
                        rowCount={total}
                        pagination
                        paginationMode="server"
                        loading={isLoadingData}
                        checkboxSelection
                        disableRowSelectionOnClick
                        disableSelectionOnClick
                    />


                </Box>
            </MUICssVarsProvider>


                <Modal open={!!openModal} onClose={() => {setOpenModal(false);}} sx={{zIndex:11000}}>
                    <ModalOverflow>
                        <ModalDialog layout={'fullscreen'} aria-labelledby="modal-dialog-overflow">
                            <ModalClose />
                            <DialogTitle>
                                {articleData?.title} {renderChipByType()}
                            </DialogTitle>
                            {renderModalContent()}
                            {/* другой контент модального окна */}
                        </ModalDialog>
                    </ModalOverflow>
                </Modal>

                <AlertDialogModalOne
                    open={openDialogOne}
                    onClose={() => setOpenDialogOne(false)}
                    onConfirm={handleDeleteArticle}
                    articleId={currentArticleToDelete?.id}
                    articleTitle={currentArticleToDelete?.title}
                />
                <AlertDialogModalPlural
                    open={openDialog}
                    onClose={() => setOpenDialog(false)}
                    onConfirm={handleDeleteArticles}
                    selectedArticles={selectedRows}
                />

                {/*Модальное окно добавления*/}
                <Modal open={isOpenModalAdd} onClose={handleCloseModal}>
                    <ModalDialog>
                        <ModalClose />
                        <DialogTitle>Добавить ссылку</DialogTitle>
                        <Stack spacing={2}>
                            <FormControl error={addError !== ''}>
                                <FormLabel>URL</FormLabel>
                                <Input
                                    autoFocus
                                    required
                                    value={articleUrl}
                                    onChange={handleInputChange}
                                    disabled={addComplete || isAddProcess}
                                />
                                {addError && (
                                    <FormHelperText>
                                        <InfoOutlined />
                                        {addError}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            {addComplete === false ? (
                                <Button onClick={handleAddArticle} loading={isAddProcess}>добавить</Button>
                            ) : (
                                <Typography sx={{
                                    width: '100%',
                                    textAlign: 'center',
                                    display: 'block',
                                    marginTop: 2 // или другое значение отступа сверху, если требуется
                                }}
                                >Ссылка добавлена</Typography>
                            )}
                        </Stack>
                    </ModalDialog>
                </Modal>

                <Snackbar
                    autoHideDuration={5000}
                    variant="soft"
                    color="success"
                    open={openSB}
                    onClose={() => setOpenSB(false)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    startDecorator={<PlaylistAddCheckCircleRoundedIcon />}
                    endDecorator={
                        <Button
                            onClick={() => setOpenSB(false)}
                            size="sm"
                            variant="soft"
                            color="success"
                        >
                            Закрыть
                        </Button>
                    }
                >
                    Статья отправлен на доработку.
                </Snackbar>
            </LayoutDashboard>


        </>
    );
};

export default AtWork;
