import React from 'react';
import LayoutDashboard from "../../components/LayoutDashboard";

const Invitations = () => {
    return (
        <LayoutDashboard>
            <h1>Invitations</h1>
            {/* Содержимое dashboard */}
        </LayoutDashboard>
    );
};

export default Invitations;
