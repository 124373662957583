import React, {useState, useContext, useEffect} from 'react';
import {AuthContext} from "../../context/AuthContext";
import {useLocation} from 'react-router-dom';

import GlobalStyles from '@mui/joy/GlobalStyles';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Divider from '@mui/joy/Divider';
import IconButton from '@mui/joy/IconButton';
import Input from '@mui/joy/Input';

import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';

import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';

import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import SupportRoundedIcon from '@mui/icons-material/SupportRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';

import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import BrightnessAutoRoundedIcon from '@mui/icons-material/BrightnessAutoRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import ColorSchemeToggle from "../utils/ColorSchemeToggle";
import { closeSidebar} from "../utils/utils";
import NavLinkMui from "../UI/NavLinkMui";

function Toggler({ defaultExpanded = false, renderToggle, children, nestedRoutes }) {
    const [open, setOpen] = useState(defaultExpanded);
    const location = useLocation();

    useEffect(() => {
        if (nestedRoutes.includes(location.pathname)) {
            setOpen(true);
        }
    }, [location, nestedRoutes]);

    return (
        <React.Fragment>
            {renderToggle({ open, setOpen })}
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateRows: open ? '1fr' : '0fr',
                    transition: '0.2s ease',
                    '& > *': { overflow: 'hidden' },
                }}
            >
                {children}
            </Box>
        </React.Fragment>
    );
}


export default function Sidebar() {
    const { user, logout } = useContext(AuthContext);


    return (
        <Sheet
            className="Sidebar"
            sx={{
                position: { xs: 'fixed', md: 'sticky' },
                transform: {
                    xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
                    md: 'none',
                },
                transition: 'transform 0.4s, width 0.4s',
                zIndex: 10000,
                height: '100dvh',
                width: 'var(--Sidebar-width)',
                top: 0,
                p: 2,
                flexShrink: 0,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                borderRight: '1px solid',
                borderColor: 'divider',
            }}
        >
            <GlobalStyles
                styles={(theme) => ({
                    ':root': {
                        '--Sidebar-width': '220px',
                        [theme.breakpoints.up('lg')]: {
                            '--Sidebar-width': '240px',
                        },
                    },
                })}
            />
            <Box
                className="Sidebar-overlay"
                sx={{
                    position: 'fixed',
                    zIndex: 9998,
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    opacity: 'var(--SideNavigation-slideIn)',
                    backgroundColor: 'var(--joy-palette-background-backdrop)',
                    transition: 'opacity 0.4s',
                    transform: {
                        xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
                        lg: 'translateX(-100%)',
                    },
                }}
                onClick={() => closeSidebar()}
            />
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <IconButton variant="soft" color="primary" size="sm">
                    <BrightnessAutoRoundedIcon />
                </IconButton>
                <Typography level="title-lg">AIW</Typography>
                <ColorSchemeToggle sx={{ ml: 'auto' }} />
            </Box>
            <Input size="sm" startDecorator={<SearchRoundedIcon />} placeholder="Search" />
            <Box
                sx={{
                    minHeight: 0,
                    overflow: 'hidden auto',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    [`& .${listItemButtonClasses.root}`]: {
                        gap: 1.5,
                    },
                }}
            >
                <List
                    size="sm"
                    sx={{
                        gap: 1,
                        '--List-nestedInsetStart': '30px',
                        '--ListItem-radius': (theme) => theme.vars.radius.sm,
                    }}
                >

                    <ListItem>
                        <ListItemButton component={NavLinkMui} to="/dashboard">
                            <DashboardRoundedIcon />
                            <ListItemContent>
                                <Typography level="title-sm">Dashboard</Typography>
                            </ListItemContent>
                        </ListItemButton>
                    </ListItem>

                    <ListItem>
                        <ListItemButton component={NavLinkMui} to="/atwork">
                            <DashboardRoundedIcon />
                            <ListItemContent>
                                <Typography level="title-sm">В работе</Typography>
                            </ListItemContent>
                        </ListItemButton>
                    </ListItem>

                    <ListItem nested>
                        <Toggler
                            nestedRoutes={['/users/all', '/users/invitations', '/users/myprofile']}
                            renderToggle={({ open, setOpen }) => (
                                <ListItemButton onClick={() => setOpen(!open)}>
                                    <GroupRoundedIcon />
                                    <ListItemContent>
                                        <Typography level="title-sm">Пользователи</Typography>
                                    </ListItemContent>
                                    <KeyboardArrowDownIcon
                                        sx={{ transform: open ? 'rotate(180deg)' : 'none' }}
                                    />
                                </ListItemButton>
                            )}
                        >
                            <List sx={{ gap: 0.5 }}>
                                <ListItem sx={{ mt: 0.5 }}>
                                    <ListItemButton component={NavLinkMui} to="/users/all">Все пользователи</ListItemButton>
                                </ListItem>
                                <ListItem>
                                    <ListItemButton component={NavLinkMui} to="/users/invitations">Приглашения</ListItemButton>
                                </ListItem>
                                <ListItem>
                                    <ListItemButton component={NavLinkMui} to="/users/myprofile">Мой профайл</ListItemButton>
                                </ListItem>
                            </List>
                        </Toggler>
                    </ListItem>

                    <ListItem nested>
                        <Toggler
                            nestedRoutes={['/settings/global', '/settings/scrapy', '/settings/gpt', '/settings/wp']}
                            renderToggle={({ open, setOpen }) => (
                                <ListItemButton onClick={() => setOpen(!open)}>
                                    <SettingsRoundedIcon />

                                    <ListItemContent>
                                        <Typography level="title-sm">Настройки</Typography>
                                    </ListItemContent>
                                    <KeyboardArrowDownIcon
                                        sx={{ transform: open ? 'rotate(180deg)' : 'none' }}
                                    />
                                </ListItemButton>
                            )}
                        >
                            <List sx={{ gap: 0.5 }}>
                                <ListItem sx={{ mt: 0.5 }}>
                                    <ListItemButton component={NavLinkMui} to="/settings/global">Общие</ListItemButton>
                                </ListItem>
                                <ListItem>
                                    <ListItemButton component={NavLinkMui} to="/settings/scrapy">Парсинг</ListItemButton>
                                </ListItem>
                                <ListItem>
                                    <ListItemButton component={NavLinkMui} to="/settings/gpt">ChatGPT</ListItemButton>
                                </ListItem>
                                <ListItem>
                                    <ListItemButton component={NavLinkMui} to="/settings/wp">WordPress</ListItemButton>
                                </ListItem>
                            </List>
                        </Toggler>
                    </ListItem>


                </List>

                <List
                    size="sm"
                    sx={{
                        mt: 'auto',
                        flexGrow: 0,
                        '--ListItem-radius': (theme) => theme.vars.radius.sm,
                        '--List-gap': '8px',
                        mb: 2,
                    }}
                >




                    <ListItem>
                        <ListItemButton component={NavLinkMui} to="/help">
                            <SupportRoundedIcon />
                            Помощь
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
            <Divider />
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Avatar
                    variant="outlined"
                    size="sm"
                >{user?.name ? user.name.charAt(0) : '?'}</Avatar>
                <Box sx={{ minWidth: 0, flex: 1 }}>
                    <Typography level="title-sm">{user?.name || 'NoName'}</Typography>
                    <Typography level="body-xs">{user?.email || 'NoEmail'}</Typography>

                </Box>
                <IconButton size="sm" variant="plain" color="neutral" onClick={logout}>
                    <LogoutRoundedIcon />
                </IconButton>
            </Box>
        </Sheet>
    );
}