import React, { useState } from 'react';
import LayoutDashboard from "../../components/LayoutDashboard";

import Box from '@mui/material/Box';

import Typography from "@mui/joy/Typography";

import {NavLink, useNavigate} from 'react-router-dom';
import Button from "@mui/joy/Button";
import Checkbox from '@mui/joy/Checkbox';
import Radio from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';

import { styled } from '@mui/joy';

import Table from '@mui/joy/Table';
import Papa from 'papaparse';


const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const CSVFileUpload = ({ onFileChange, disabled }) => {
    return (
        <Button
            sx={{mr:1}}
            component="label"
            variant="outlined"
            color="neutral"
            disabled={disabled}
        >
            Импорт CSV
            <VisuallyHiddenInput
                type="file"
                accept=".csv"
                onChange={onFileChange}
            />
        </Button>
    );
};




const WorkAdd = () => {

    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [dataRaw, setDataRaw] = useState([]);
    const [selectedColumn, setSelectedColumn] = useState(null);
    const [isFirstRowHeaders, setIsFirstRowHeaders] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [bntOkLoading,setBntOkLoading]= useState(false);
    const [totalFin,setTotalFin]= useState(0);
    const [totalC,setTotalC]= useState(0);
    const [isWork,setIsWork]= useState(false);
    const [isLoadDataOk, setIsLoadDataOk]= useState(false);

    const navigate = useNavigate();
    // Функция для обработки изменения состояния чекбокса
    const handleCheckboxChange = (event) => {
        setIsFirstRowHeaders(event.target.checked);
    };



    // Кнопка OK активна только если выбрана колонка
    const isOkButtonEnabled = selectedColumn !== null;

    const handleRadioChange = (event) => {
        setSelectedColumn(event.target.value);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            Papa.parse(file, {
                complete: (result) => {
                    const rows = result.data;
                    if (rows.length > 0) {
                        // Установка заголовков столбцов
                        setColumns(rows[0].map((header, index) => ({ field: `col${index}`, headerName: header, width: 150 })));
                        // Установка только первых двух строк данных (исключая заголовки)
                        setData(rows.slice(1, 3).map((row, index) => {
                            const rowData = {};
                            row.forEach((cell, colIndex) => {
                                rowData[`col${colIndex}`] = cell;
                            });
                            return { id: index, ...rowData };
                        }));

                        setDataRaw(rows.map((row, index) => {
                            const rowData = {};
                            row.forEach((cell, colIndex) => {
                                rowData[`col${colIndex}`] = cell;
                            });
                            return { id: index, ...rowData };
                        }));

                        setIsLoadDataOk(true);
                    }
                }
            });
        }
    };


    const addArticle = async (url) => {
        try {
            new URL(url);
        } catch (error) {
            return "Некорректный URL";
        }

        const token = localStorage.getItem('token');
        if (token) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/articles/add`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ url, type: "non", status: 0 }) // Подставьте нужные значения
                });

                if (response.ok) {
                    return "OK";
                } else {
                    const errorData = await response.json();
                    return errorData.message || "Ошибка запроса";
                }
            } catch (error) {
                console.error('Ошибка при добавлении статьи', error);
                return "Ошибка запроса";
            }
        }
        return "Токен не найден";
    };

    const handleOkClick = async () => {
        setBntOkLoading(true);
        setIsWork(true);
        const columnIndex = columns.findIndex(col => col.headerName === selectedColumn);
        const startIndex = isFirstRowHeaders ? 1 : 0;
        const columnData = dataRaw.slice(startIndex).map(row => row[`col${columnIndex}`]);
        let fin = 0;
        setTotalC(columnData.length)
        setTotalFin(0)
        // Очищаем текущие данные в таблице перед началом обработки
        setTableData([]);

        for (const item of columnData) {
            const result = await addArticle(item);
            // Обновляем состояние tableData с каждым новым результатом
            setTableData(prevTableData => [...prevTableData, { url: item, result }]);
            fin +=1;
            setTotalFin(fin)
        }
        setBntOkLoading(false);
        setIsWork(false);
        navigate('/atwork');
    };

    return (
        <LayoutDashboard>
            <Box
                sx={{
                    display: 'flex',
                    mb: 1,
                    gap: 1,
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: { xs: 'start', sm: 'center' },
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                }}
            >
                <Typography level="h2" component="h1">
                    Добавивть статьи
                </Typography>
                <Box>
                    <CSVFileUpload onFileChange={handleFileChange} disabled={isWork}/>
                    <NavLink
                        to={'/atwork'}
                    >
                        <Button
                            color="primary"
                            size="sm"
                            disabled={isWork}
                        >
                            назад
                        </Button>

                    </NavLink>
                </Box>
            </Box>
            {/* Содержимое dashboard */}
            {isLoadDataOk && (
                <>
                    <Box>

                        <Box sx={{ height: 200, width: '100%', overflowX: 'auto' }}>
                            Ипорт данних:
                            <Box sx={{ display: 'flex', gap: 3 }}>
                                <Checkbox
                                    label="Первая строка - названия"
                                    checked={isFirstRowHeaders}
                                    onChange={handleCheckboxChange}
                                    disabled={isWork}
                                />
                            </Box>
                            Таблица:
                            <RadioGroup
                                name="radio-buttons"
                                value={selectedColumn}
                                onChange={handleRadioChange}

                            >
                                <Table
                                    borderAxis="x"
                                    color="primary"
                                    size="sm"
                                    variant="plain"
                                >
                                    <thead>
                                    <tr>
                                        {columns.map((col) => (
                                            <th key={col.field} style={{ width: col.width }}>
                                                <Radio
                                                    value={col.headerName}
                                                    slotProps={{ input: { 'aria-label': col.headerName } }}
                                                    label={col.headerName}
                                                    disabled={isWork}
                                                />

                                            </th>
                                        ))}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {data.map((row, index) => (
                                        <tr key={index}>
                                            {columns.map((col) => (
                                                <td key={col.field}>{row[col.field]}</td>
                                            ))}
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </RadioGroup>
                        </Box>
                        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', justifyContent: 'flex-end',mt:3 }}>
                            {totalFin} из {totalC}<Button disabled={!isOkButtonEnabled} onClick={handleOkClick} loading={bntOkLoading}>OK</Button>
                        </Box>
                    </Box>

                    <Box sx={{ height: '100%', width: '100%', overflow: 'auto' }}>
                        <Table aria-label="basic table">
                            <thead>
                            <tr>
                                <th style={{ width: '80%' }}>URL</th>
                                <th>Result</th>
                            </tr>
                            </thead>
                            <tbody>
                            {tableData.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.url}</td>
                                    <td>{item.result}</td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>

                    </Box>
                </>
            )}
        </LayoutDashboard>
    );
};

export default WorkAdd;
