import React from 'react';
import { Box, CssVarsProvider } from '@mui/joy';

const Layout = ({ children }) => {
    return (
        <CssVarsProvider>
            <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                {/* Header */}
                <Box sx={{ flexGrow: 1 }}>
                    {children}
                </Box>
                {/* Footer */}
            </Box>
        </CssVarsProvider>
    );
};

export default Layout;
