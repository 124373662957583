import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import ProtectedRoute from './components/ProtectedRoute';
import routes from "./routes";



function App() {




    return (
        <Router>
            <Routes>
                {routes.map(route => (
                    <Route
                        key={route.path}
                        path={route.path}
                        element={
                            route.protected ?
                                (<ProtectedRoute>{React.createElement(route.element)}</ProtectedRoute>) :
                                React.createElement(route.element)
                        }
                    />
                ))}
            </Routes>
        </Router>
    );
}

export default App;
