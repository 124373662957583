import React from 'react';
import { Box, CssVarsProvider, extendTheme } from '@mui/joy';
import CssBaseline from '@mui/joy/CssBaseline';

import Header from "./Parts/Header";
import Sidebar from "./Parts/Sidebar";
import {useLocation} from "react-router-dom";
import routes from "../routes";
import useDocumentTitle from "./utils/useDocumentTitle";


const githubTheme = extendTheme({
    components: {
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    border: 1,
                    borderColor: "#C1C2C5",
                    borderStyle: "solid",
                    borderRadius: 10,
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
                    backgroundColor: "#C1C2C5",
                    color: "#C1C2C5",
                    padding: 10,
                },
            },
        },
    },
    vars: {
        zIndex: {
            modal: 13000, // Пример значения для zIndex модального окна
        },
    },
});

const LayoutDashboard = ({ children }) => {

    const location = useLocation();

    const currentRoute = routes.find(route => route.path === location.pathname);
    useDocumentTitle(currentRoute ? currentRoute.title : "AIW");

    return (
        <CssVarsProvider disableTransitionOnChange theme={githubTheme}>
            <CssBaseline />
            <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
                <Header />
                <Sidebar />
                <Box
                    component="main"
                    className="MainContent"
                    sx={{
                        px: { xs: 2, md: 6 },
                        pt: {
                            xs: 'calc(12px + var(--Header-height))',
                            sm: 'calc(12px + var(--Header-height))',
                            md: 3,
                        },
                        pb: { xs: 2, sm: 2, md: 3 },
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: 0,
                        height: '100dvh',
                        gap: 1,
                    }}
                >

                    {children}
                </Box>
            </Box>
        </CssVarsProvider>
    );
};

export default LayoutDashboard;
