import React, { forwardRef } from "react";
import { NavLink } from "react-router-dom";

const NavLinkMui = forwardRef((props, ref) => {
    return (
        <NavLink
            ref={ref}
            {...props}
            className={({ isActive }) =>
                isActive ? `${props.className} Mui-selected` : props.className}
        />
    );
});

export default NavLinkMui;
