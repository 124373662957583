import React, { useState, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import Layout from '../components/Layout';
import { CssVarsProvider, Box, Input, Button, Typography, Container } from '@mui/joy';
import { useNavigate } from 'react-router-dom';
import LEInput from "../components/UI/LEInput";
import Checkbox from '@mui/joy/Checkbox';
import Tooltip from '@mui/joy/Tooltip';

import InfoIcon from '@mui/icons-material/Info';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const { login } = useContext(AuthContext);

    const handleRememberMeChange = (e) => {
        setRememberMe(e.target.checked);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ username:username, password:password, remember: rememberMe })
            });

            const data = await response.json();

            if (!response.ok) {
                setError(data.message);
            } else {
                login(data.result, data.token); // Обновите контекст пользователя с этими данными
                navigate('/dashboard');
            }
        } catch (err) {
            setError('Произошла ошибка при входе');
        }
    };

    return (
        <Layout>
            <CssVarsProvider>
                <Container maxWidth="sm" sx={{ mt: 4 }}>
                    <Box
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2
                        }}
                        onSubmit={handleSubmit}
                    >
                        <Typography level="h4" component="h1" sx={{ textAlign: 'center' }}>
                            Вход в систему
                        </Typography>
                        <LEInput
                            label="Логин"
                            placeholder="login"
                            type="text"
                            variant="outlined"
                            error={Boolean(error)}
                            helperText={error || "Неверный логин"}
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                        <LEInput
                            label="Пароль"
                            placeholder="password"
                            type="password"
                            variant="outlined"
                            error={Boolean(error)}
                            helperText={error || "Неверный пароль"}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <Box display="flex" alignItems="center">
                            <Checkbox
                                label="запомнить меня"
                                onChange={handleRememberMeChange}
                                checked={rememberMe}
                            />
                            &nbsp;&nbsp;
                            <Tooltip title="Оставаться в системе 30 дней" placement="right-start" size="sm">
                                <InfoIcon size="sm" />
                            </Tooltip>
                        </Box>

                        <Button type="submit" variant="solid" color="primary" sx={{ mt: 2 }}>
                            Войти
                        </Button>
                    </Box>
                </Container>
            </CssVarsProvider>
        </Layout>
    );
};

export default Login;
