import React from 'react';
import LayoutDashboard from "../components/LayoutDashboard";

const Help = () => {
    return (
        <LayoutDashboard>
            <h1>Помощь</h1>
            {/* Содержимое dashboard */}
        </LayoutDashboard>
    );
};

export default Help;
