import React, {useState,useEffect} from 'react';
import LayoutDashboard from "../../components/LayoutDashboard";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import FormHelperText from '@mui/joy/FormHelperText';
import Input from '@mui/joy/Input';
import Snackbar from '@mui/joy/Snackbar';


import IconButton from '@mui/joy/IconButton';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PlaylistAddCheckCircleRoundedIcon from '@mui/icons-material/PlaylistAddCheckCircleRounded';
import InfoOutlined from '@mui/icons-material/InfoOutlined';

import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Switch from "@mui/joy/Switch";

const GptSettings = () => {
    const [openSB, setOpenSB] = React.useState(false);

    const [gptSettings, setGptSettings] = useState({
        api: '',
        model: '',
        instructions_articles: '',
        instructions_add_suggest: '',
        markdown:'',
        dalle_model: '',
        dalle_size:'',
        images_count: 0,
        images_choise: 0,
        streams:0,
        instructions_images: ''
    });

    const [isEditing, setIsEditing] = useState({
        api: false,
        model: false,
        instructions_articles: false,
        instructions_add_suggest: false,
        markdown:false,
        dalle_model: false,
        dalle_size: false,
        images_count: false,
        images_choise: false,
        streams: false,
        instructions_images: false
    });

    const [errors, setErrors] = useState({
        api: '',
        model: '',
        instructions_articles: '',
        instructions_add_suggest: '',
        markdown:'',
        dalle_model: '',
        dalle_size: '',
        images_count: '',
        images_choise: '',
        streams: '',
        instructions_images: ''
    });


    const handleEditClick = (field) => {
        setIsEditing(prev => ({ ...prev, [field]: true }));
    };

    const handleCancelClick = (field) => {
        setIsEditing(prev => ({ ...prev, [field]: false }));
    };

    const updateGptSettings = async (updatedSettings) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/settings/gpt`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(updatedSettings)
            });

            if (response.ok) {
                const data = await response.json();
                setOpenSB(true)
                console.log('Настройки успешно обновлены', data);
            } else {
                console.error('Ошибка при обновлении настроек GPT');
            }
        } catch (error) {
            console.error('Ошибка запроса:', error);
        }
    };


    const validateInput = (field, value) => {
        if (typeof value === 'string' && value.trim() === '') {
            setErrors(prev => ({ ...prev, [field]: 'Это поле не может быть пустым' }));
            return false;
        } else if (typeof value === 'number' && isNaN(value)) {
            setErrors(prev => ({ ...prev, [field]: 'Необходимо указать число' }));
            return false;
        }
        setErrors(prev => ({ ...prev, [field]: '' }));
        return true;
    };

    const handleSaveClick = async (field) => {
        const isValid = validateInput(field, gptSettings[field]);
        if (!isValid) return;

        // Подготовка объекта с обновленными данными
        const updatedSettings = { [field]: gptSettings[field] };

        // Отправка обновленных данных на сервер
        await updateGptSettings(updatedSettings);

        // Сброс режима редактирования
        setIsEditing(prev => ({ ...prev, [field]: false }));
    };

    useEffect(() => {
        const fetchGptSettings = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/settings/gpt`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setGptSettings(data);
                } else {
                    console.error('Ошибка при получении настроек GPT');
                }
            } catch (error) {
                console.error('Ошибка запроса:', error);
            }
        };

        fetchGptSettings();
    }, []);

    // Функция для обработки изменения состояния переключателя
    const handleMarkdownChange = async (event) => {
        const newMarkdownValue = event.target.checked;
        // Обновление настроек в базе данных
        await updateGptSettings({ markdown: newMarkdownValue });

        // Обновление локального состояния
        setGptSettings(prevSettings => ({
            ...prevSettings,
            markdown: newMarkdownValue
        }));
    };

    return (
        <LayoutDashboard>
            <Box
                sx={{
                    display: 'flex',
                    mb: 1,
                    gap: 1,
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: { xs: 'start', sm: 'center' },
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                }}
            >
                <Typography level="h2" component="h1">
                    Настройки AI
                </Typography>



            </Box>
            {/* Содержимое dashboard */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column', // Изменено на 'column' для вертикального расположения
                    gap: 2,
                    overflowX: 'hidden', // Добавляет горизонтальную прокрутку
                    overflowY: 'auto', // Убирает вертикальную прокрутку
                    maxHeight: '100%', // Опционально, устанавливает максимальную ширину
                }}
            >
                <Typography level="h3" component="h1">
                   chatGPT
                </Typography>
                {/* Поле API */}
                <FormControl sx={{ width: '100%' }} error={!!errors.api}>
                    <FormLabel>Ключ API:</FormLabel>
                    {!isEditing.api ? (
                        <Typography sx={{cursor:"pointer"}} onClick={() => handleEditClick('api')}>{gptSettings.api}</Typography>
                    ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Input
                                sx={{ flexGrow: 1 }}
                                value={gptSettings.api}
                                onChange={(e) => setGptSettings({...gptSettings, api: e.target.value})}
                                placeholder="API key"
                            />
                            <IconButton variant="solid" color="primary" onClick={() => handleSaveClick('api', gptSettings.api)}>
                                <CheckCircleIcon />
                            </IconButton>
                            <IconButton variant="solid" color="danger" onClick={() => handleCancelClick('api')}>
                                <CancelIcon />
                            </IconButton>
                        </Box>
                    )}
                    {errors.api && <FormHelperText> <InfoOutlined />{errors.api}</FormHelperText>}
                </FormControl>

                {/* Поле Model */}
                <FormControl sx={{ width: '100%' }} error={!!errors.model}>
                    <FormLabel>Модель чатаGPT:</FormLabel>
                    {!isEditing.model ? (
                        <Typography sx={{cursor:"pointer"}} onClick={() => handleEditClick('model')}>{gptSettings.model}</Typography>
                    ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Input sx={{ flexGrow: 1 }} value={gptSettings.model} onChange={(e) => setGptSettings({...gptSettings, model: e.target.value})} placeholder="Model name" />
                            <IconButton variant="solid" color="primary" onClick={() => handleSaveClick('model', gptSettings.model)}>
                                <CheckCircleIcon />
                            </IconButton>
                            <IconButton variant="solid" color="danger" onClick={() => handleCancelClick('model')}>
                                <CancelIcon />
                            </IconButton>
                        </Box>
                    )}
                    {errors.model && <FormHelperText> <InfoOutlined />{errors.model}</FormHelperText>}
                </FormControl>


                {/* Поле streams */}
                <FormControl sx={{ width: '100%' }} error={!!errors.streams}>
                    <FormLabel>Потоков:</FormLabel>
                    {!isEditing.streams ? (
                        <Typography sx={{cursor:"pointer"}} onClick={() => handleEditClick('streams')}>{gptSettings.streams}</Typography>
                    ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Input sx={{ flexGrow: 1 }} type="number" value={gptSettings.streams} onChange={(e) => setGptSettings({...gptSettings, streams: e.target.value})} placeholder="10" />
                            <IconButton variant="solid" color="primary" onClick={() => handleSaveClick('streams', gptSettings.streams)}>
                                <CheckCircleIcon />
                            </IconButton>
                            <IconButton variant="solid" color="danger" onClick={() => handleCancelClick('streams')}>
                                <CancelIcon />
                            </IconButton>
                        </Box>
                    )}
                    {errors.streams && <FormHelperText> <InfoOutlined />{errors.streams}</FormHelperText>}
                </FormControl>

                {/* Поле Instructions for Articles */}
                <FormControl sx={{ width: '100%' }} error={!!errors.instructions_articles}>
                    <FormLabel>Инструкция для написания статьи:</FormLabel>
                    {!isEditing.instructions_articles ? (
                        <Typography sx={{cursor:"pointer"}} onClick={() => handleEditClick('instructions_articles')}>{gptSettings.instructions_articles}</Typography>
                    ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Input sx={{ flexGrow: 1 }} value={gptSettings.instructions_articles} onChange={(e) => setGptSettings({...gptSettings, instructions_articles: e.target.value})} placeholder="Instructions for articles" />
                            <IconButton variant="solid" color="primary" onClick={() => handleSaveClick('instructions_articles', gptSettings.instructions_articles)}>
                                <CheckCircleIcon />
                            </IconButton>
                            <IconButton variant="solid" color="danger" onClick={() => handleCancelClick('instructions_articles')}>
                                <CancelIcon />
                            </IconButton>
                        </Box>
                    )}
                    {errors.instructions_articles && <FormHelperText> <InfoOutlined />{errors.instructions_articles}</FormHelperText>}
                </FormControl>

                {/* Поле Instructions for Add Suggest */}
                <FormControl sx={{ width: '100%' }} error={!!errors.instructions_add_suggest}>
                    <FormLabel>Инструкция для добавлений подсказок поисковых систем:</FormLabel>
                    {!isEditing.instructions_add_suggest ? (
                        <Typography sx={{cursor:"pointer"}} onClick={() => handleEditClick('instructions_add_suggest')}>{gptSettings.instructions_add_suggest}</Typography>
                    ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Input sx={{ flexGrow: 1 }} value={gptSettings.instructions_add_suggest} onChange={(e) => setGptSettings({...gptSettings, instructions_add_suggest: e.target.value})} placeholder="Instructions for add suggest" />
                            <IconButton variant="solid" color="primary" onClick={() => handleSaveClick('instructions_add_suggest', gptSettings.instructions_add_suggest)}>
                                <CheckCircleIcon />
                            </IconButton>
                            <IconButton variant="solid" color="danger" onClick={() => handleCancelClick('instructions_add_suggest')}>
                                <CancelIcon />
                            </IconButton>
                        </Box>
                    )}
                    {errors.instructions_add_suggest && <FormHelperText> <InfoOutlined />{errors.instructions_add_suggest}</FormHelperText>}
                </FormControl>

                {/* Поле Markdown */}
                <FormControl sx={{ width: '100%' }} error={!!errors.markdown}>
                    <FormLabel>Markdown:</FormLabel>
                    <Typography
                        component="label"
                        endDecorator={<Switch checked={gptSettings.markdown}/>}
                        onChange={handleMarkdownChange}
                    >
                        использовать разметку
                    </Typography>
                    {errors.markdown && <FormHelperText> <InfoOutlined />{errors.markdown}</FormHelperText>}
                </FormControl>

                <Typography level="h3" component="h1">
                    DALL-E
                </Typography>
                {/* Поле DALL-E Model */}
                <FormControl sx={{ width: '100%' }} error={!!errors.dalle_model}>
                    <FormLabel>Модель DALL-E:</FormLabel>
                    {!isEditing.dalle_model ? (
                        <Typography sx={{cursor:"pointer"}} onClick={() => handleEditClick('dalle_model')}>{gptSettings.dalle_model}</Typography>
                    ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Input sx={{ flexGrow: 1 }} value={gptSettings.dalle_model} onChange={(e) => setGptSettings({...gptSettings, dalle_model: e.target.value})} placeholder="DALL-E model name" />
                            <IconButton variant="solid" color="primary" onClick={() => handleSaveClick('dalle_model')}>
                                <CheckCircleIcon />
                            </IconButton>
                            <IconButton variant="solid" color="danger" onClick={() => handleCancelClick('dalle_model')}>
                                <CancelIcon />
                            </IconButton>
                        </Box>
                    )}
                    {errors.dalle_model && <FormHelperText> <InfoOutlined />{errors.dalle_model}</FormHelperText>}
                </FormControl>


                {/* Поле DALL-E size */}
                <FormControl sx={{ width: '100%' }} error={!!errors.dalle_size}>
                    <FormLabel>Size DALL-E:</FormLabel>
                    {!isEditing.dalle_size ? (
                        <Typography sx={{cursor:"pointer"}} onClick={() => handleEditClick('dalle_size')}>{gptSettings.dalle_size}</Typography>
                    ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Select
                                sx={{ flexGrow: 1 }}
                                value={gptSettings.dalle_size}
                                onChange={(e, NewValue) => setGptSettings({...gptSettings, dalle_size: NewValue})}
                            >
                                <Option value="256x256">256×256</Option>
                                <Option value="512x512">512×512</Option>
                                <Option value="1024x1024">1024×1024</Option>
                                {/* Добавьте здесь другие значения размера, если необходимо */}
                            </Select>
                            <IconButton variant="solid" color="primary" onClick={() => handleSaveClick('dalle_size')}>
                                <CheckCircleIcon />
                            </IconButton>
                            <IconButton variant="solid" color="danger" onClick={() => handleCancelClick('dalle_size')}>
                                <CancelIcon />
                            </IconButton>
                        </Box>
                    )}
                    {errors.dalle_size && <FormHelperText> <InfoOutlined />{errors.dalle_size}</FormHelperText>}
                </FormControl>




                {/* Поле Images Count */}
                <FormControl sx={{ width: '100%' }} error={!!errors.images_count}>
                    <FormLabel>Количество добавляемых изображений:</FormLabel>
                    {!isEditing.images_count ? (
                        <Typography sx={{cursor:"pointer"}} onClick={() => handleEditClick('images_count')}>{gptSettings.images_count}</Typography>
                    ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Input sx={{ flexGrow: 1 }} type="number" value={gptSettings.images_count} onChange={(e) => setGptSettings({...gptSettings, images_count: e.target.value})} placeholder="Images count" />
                            <IconButton variant="solid" color="primary" onClick={() => handleSaveClick('images_count', gptSettings.images_count)}>
                                <CheckCircleIcon />
                            </IconButton>
                            <IconButton variant="solid" color="danger" onClick={() => handleCancelClick('images_count')}>
                                <CancelIcon />
                            </IconButton>
                        </Box>
                    )}
                    {errors.images_count && <FormHelperText> <InfoOutlined />{errors.images_count}</FormHelperText>}
                </FormControl>

                {/* Поле Images Choice */}
                <FormControl sx={{ width: '100%' }} error={!!errors.images_choise}>
                    <FormLabel>Выбор изображений:</FormLabel>
                    {!isEditing.images_choise ? (
                        <Typography sx={{cursor:"pointer"}} onClick={() => handleEditClick('images_choise')}>
                            {gptSettings.images_choise === 0 ? 'Случайно' :
                                gptSettings.images_choise === 1 ? 'Первый' :
                                    gptSettings.images_choise === 2 ? 'Послдений' :
                                        'неизвестно'}
                        </Typography>
                    ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Select
                                sx={{ flexGrow: 1 }}
                                defaultValue={gptSettings.images_choise}
                                onChange={(event,newValue) => setGptSettings({...gptSettings, images_choise: newValue})}
                            >
                                <Option value={0}>Случайно</Option>
                                <Option value={1}>Первый</Option>
                                <Option value={2}>Последний</Option>
                            </Select>
                            <IconButton variant="solid" color="primary" onClick={() => handleSaveClick('images_choise', gptSettings.images_choise)}>
                                <CheckCircleIcon />
                            </IconButton>
                            <IconButton variant="solid" color="danger" onClick={() => handleCancelClick('images_choise')}>
                                <CancelIcon />
                            </IconButton>
                        </Box>
                    )}
                    {errors.images_choise && <FormHelperText> <InfoOutlined />{errors.images_choise}</FormHelperText>}
                </FormControl>

                {/* Поле Instructions for Images
                <FormControl sx={{ width: '100%' }} error={!!errors.instructions_images}>
                    <FormLabel>Инструкция для перерисования изображения:</FormLabel>
                    {!isEditing.instructions_images ? (
                        <Typography sx={{cursor:"pointer"}} onClick={() => handleEditClick('instructions_images')}>{gptSettings.instructions_images}</Typography>
                    ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Input sx={{ flexGrow: 1 }} value={gptSettings.instructions_images} onChange={(e) => setGptSettings({...gptSettings, instructions_images: e.target.value})} placeholder="Instructions for images" />
                            <IconButton variant="solid" color="primary" onClick={() => handleSaveClick('instructions_images', gptSettings.instructions_images)}>
                                <CheckCircleIcon />
                            </IconButton>
                            <IconButton variant="solid" color="danger" onClick={() => handleCancelClick('instructions_images')}>
                                <CancelIcon />
                            </IconButton>
                        </Box>
                    )}
                    {errors.instructions_images && <FormHelperText> <InfoOutlined />{errors.instructions_images}</FormHelperText>}
                </FormControl>
                */}
            </Box>

            <Snackbar
                autoHideDuration={5000}
                variant="soft"
                color="success"
                open={openSB}
                onClose={() => setOpenSB(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                startDecorator={<PlaylistAddCheckCircleRoundedIcon />}
                endDecorator={
                    <Button
                        onClick={() => setOpenSB(false)}
                        size="sm"
                        variant="soft"
                        color="success"
                    >
                        Закрыть
                    </Button>
                }
            >
                Настройки успешно обновлены.
            </Snackbar>
            {/* Содержимое dashboard */}
        </LayoutDashboard>
    );
};

export default GptSettings;
