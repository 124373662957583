import React,{useEffect,useState} from 'react';
import LayoutDashboard from "../components/LayoutDashboard";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
import Box from "@mui/joy/Box";


import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardActions from '@mui/joy/CardActions';

import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import CircularProgress from '@mui/joy/CircularProgress';

const Dashboard = () => {
    const [isParsing, setIsParsing] = useState(false);
    const [activeSpidersCount, setActiveSpidersCount] = useState(0);
    const [countAtWork, setCountAtWork] = useState(0); // новое состояние для хранения количества

    const [isChatGPTActive, setIsChatGPTActive] = useState(false);
    const [chatGPTCount, setChatGPTCount] = useState(0);

    const [isWordPressActive, setIsWordPressActive] = useState(false);
    const [wordpressCount, setWordPressCount] = useState(0);


    const checkParsingStatus = async () => {
        try {
            // Запрос на получение статуса парсинга
            const responseParsing = await fetch(`${process.env.REACT_APP_API_SERVER_URL}/parsing-status`);
            const dataParsing = await responseParsing.json();
            setIsParsing(dataParsing.isParsing);
            setActiveSpidersCount(dataParsing.activeSpidersCount);

            // Определение параметров запроса в зависимости от состояния парсинга
            const queryParams = isParsing ? "status=1" : "status=true&list=0,10";

            // Запрос на получение количества статей в работе
            const responseCount = await fetch(`${process.env.REACT_APP_API_URL}/api/articles/getcount?${queryParams}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            const dataCount = await responseCount.json();
            setCountAtWork(dataCount.totalItems);
        } catch (error) {
            console.error('Ошибка при получении данных:', error);
        }
    };

    // Функция для проверки статуса Chat GPT
    const checkChatGPTStatus = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_SERVER_URL}/chatgpt-status`);
            const data = await response.json();
            setIsChatGPTActive(data.isChating);

            const queryParams = isChatGPTActive ? "status=2" : "status=2";
            const responseCount = await fetch(`${process.env.REACT_APP_API_URL}/api/articles/getcount?${queryParams}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            const dataCount = await responseCount.json();
            console.log("dataCount",dataCount)
            setChatGPTCount(dataCount.totalItems);
        } catch (error) {
            console.error('Ошибка при получении данных Chat GPT:', error);
        }
    };

    // Функция для запуска Chat GPT
    const handleStartChatGpt = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_SERVER_URL}/start-chating`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
            });
            const data = await response.json();
            console.log(data.message); // Выведите сообщение о статусе Chat GPT
            checkChatGPTStatus();
        } catch (error) {
            console.error('Ошибка при запуске Chat GPT:', error);
        }
    };

    // Функция для проверки статуса WordPress
    const checkWordPressStatus = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_SERVER_URL}/publish-status`);
            const data = await response.json();
            setIsWordPressActive(data.isPublishing);

            const queryParams = isWordPressActive ? "status=3" : "status=3";
            const responseCount = await fetch(`${process.env.REACT_APP_API_URL}/api/articles/getcount?${queryParams}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            const dataCount = await responseCount.json();
            setWordPressCount(dataCount.totalItems);
        } catch (error) {
            console.error('Ошибка при получении данных WordPress:', error);
        }
    };

    // Функция для запуска WordPress
    const handleStartWordPress = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_SERVER_URL}/start-publish`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
            });
            const data = await response.json();
            console.log(data.message); // Выведите сообщение о статусе WordPress
            checkWordPressStatus();
        } catch (error) {
            console.error('Ошибка при запуске WordPress:', error);
        }
    };

    useEffect(() => {
        if (!isParsing) checkParsingStatus();
        if (!isChatGPTActive) checkChatGPTStatus();
        if (!isWordPressActive) checkWordPressStatus();

        // Функция для запуска интервала проверки статуса парсинга
        const startParsingInterval = (delay) => {
            return setInterval(() => {
                checkParsingStatus();
            }, delay);
        };

        // Функция для запуска интервала проверки статуса Chat GPT
        const startChatGPTInterval = (delay) => {
            return setInterval(() => {
                checkChatGPTStatus();
            }, delay);
        };

        // Функция для запуска интервала проверки статуса WordPress
        const startWordPressInterval = (delay) => {
            return setInterval(() => {
                checkWordPressStatus();
            }, delay);
        };

        // Запуск интервалов с разными периодами в зависимости от активности парсинга и Chat GPT
        let parsingInterval = startParsingInterval(isParsing ? 3000 : 10000);
        let chatGPTInterval = startChatGPTInterval(isChatGPTActive ? 3000 : 10000);
        let wordpressInterval = startWordPressInterval(isWordPressActive ? 3000 : 10000);


        // Очистка интервалов при размонтировании компонента или при изменении состояний
        return () => {
            clearInterval(parsingInterval);
            clearInterval(chatGPTInterval);
            clearInterval(wordpressInterval);
        };
    }, [isParsing, isChatGPTActive,isWordPressActive ]);

    const handleStartParsing = async () => {
        const token = localStorage.getItem('token');
        if (token) {
            setIsParsing(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_API_SERVER_URL}/start-parsing`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                });
                const data = await response.json();
                console.log(data.message); // Выведите сообщение о статусе парсинга
                checkParsingStatus();
            } catch (error) {
                console.error('Ошибка при запуске парсинга:', error);
                setIsParsing(false);
            }

        }
    };


    return (
        <LayoutDashboard>
            <Box
                sx={{
                    display: 'flex',
                    mb: 1,
                    gap: 1,
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: { xs: 'start', sm: 'center' },
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                }}
            >
                <Typography level="h2" component="h1">
                    Панель приборов
                </Typography>

            </Box>
            {/* Содержимое dashboard */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 2,
                    flexWrap: 'wrap'
                }}
            >
                <Card variant="solid" color={isParsing ? "success" : "primary"} invertedColors>
                    <Typography level="title-lg">ПАРСИНГ</Typography>
                    <CardContent orientation="horizontal">
                        {isParsing ? (
                            <CircularProgress variant="outlined" size="lg">{activeSpidersCount}</CircularProgress>
                        ) : (
                            <PauseCircleOutlineIcon sx={{fontSize:"4em"}}/>
                        )}
                        <CardContent>
                            {isParsing ? (
                                <>
                                    <Typography level="body-md">Пауки работают</Typography>
                                    <Typography level="body-md">осталось {countAtWork} статей</Typography>
                                </>

                            ) : (
                                <>
                                    <Typography level="body-md">Пауки не запущены</Typography>
                                    <Typography level="body-md">В ожидании {countAtWork} статей</Typography>
                                </>
                            )}
                            {/* Дополнительное содержимое, если требуется */}
                        </CardContent>
                    </CardContent>
                    <CardActions>
                        <Button
                            variant="solid"
                            size="sm"
                            onClick={handleStartParsing}
                            loading={isParsing}
                            disabled={countAtWork<=0}
                        >
                            Старт
                        </Button>
                    </CardActions>
                </Card>

                <Card variant="solid" color={isChatGPTActive ? "success" : "primary"} invertedColors>
                    <Typography level="title-lg">CHAT GPT</Typography>
                    <CardContent orientation="horizontal">
                        {isChatGPTActive ? (
                            <CircularProgress variant="outlined" size="lg">{chatGPTCount}</CircularProgress>
                        ) : (
                            <PauseCircleOutlineIcon sx={{ fontSize: "4em" }} />
                        )}
                        <CardContent>
                            {isChatGPTActive ? (
                                <>
                                    <Typography level="body-md">Chat GPT активен</Typography>
                                    <Typography level="body-md">В работе {chatGPTCount} задач</Typography>
                                </>
                            ) : (
                                <>
                                    <Typography level="body-md">Chat GPT неактивен</Typography>
                                    <Typography level="body-md">Ожидается {chatGPTCount} задач</Typography>
                                </>
                            )}
                        </CardContent>
                    </CardContent>
                    <CardActions>
                        <Button
                            variant="solid"
                            size="sm"
                            onClick={handleStartChatGpt}
                            loading={isChatGPTActive}
                            disabled={chatGPTCount <= 0}
                        >
                            Запустить Chat GPT
                        </Button>
                    </CardActions>
                </Card>

                {/* Карточка WordPress */}
                <Card variant="solid" color={isWordPressActive ? "success" : "primary"} invertedColors>
                    <Typography level="title-lg">WORDPRESS</Typography>
                    <CardContent orientation="horizontal">
                        {isWordPressActive ? (
                            <CircularProgress variant="outlined" size="lg">{wordpressCount}</CircularProgress>
                        ) : (
                            <PauseCircleOutlineIcon sx={{ fontSize: "4em" }} />
                        )}
                        <CardContent>
                            {isWordPressActive ? (
                                <>
                                    <Typography level="body-md">WordPress активен</Typography>
                                    <Typography level="body-md">В работе {wordpressCount} статей</Typography>
                                </>
                            ) : (
                                <>
                                    <Typography level="body-md">WordPress неактивен</Typography>
                                    <Typography level="body-md">Ожидается {wordpressCount} статей</Typography>
                                </>
                            )}
                        </CardContent>
                    </CardContent>
                    <CardActions>
                        <Button
                            variant="solid"
                            size="sm"
                            onClick={handleStartWordPress}
                            loading={isWordPressActive}
                            disabled={wordpressCount <= 0}
                        >
                            Запустить WordPress
                        </Button>
                    </CardActions>
                </Card>
            </Box>

        </LayoutDashboard>
    );
};

export default Dashboard;
