import React, {useEffect,useState} from 'react';
import LayoutDashboard from "../../components/LayoutDashboard";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";

import Table from '@mui/joy/Table';
import Sheet from "@mui/joy/Sheet";
import { Modal, ModalDialog,DialogActions, DialogTitle, DialogContent, FormControl, FormLabel, Stack,FormHelperText } from "@mui/joy";
import Input from '@mui/joy/Input';
import Autocomplete from '@mui/joy/Autocomplete';
import Button from "@mui/joy/Button";
import IconButton from '@mui/joy/IconButton';
import Skeleton from '@mui/joy/Skeleton';
import Switch from '@mui/joy/Switch';



import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import InfoOutlined from '@mui/icons-material/InfoOutlined';


const ScrapySettings = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [spiders, setSpiders] = useState([]);
    const [spidersList, setSpidersList] = useState([]);

    const [openAddModal, setOpenAddModal] = useState(false); // Добавляем состояние для управления модальным окном
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [deletingSpiderId, setDeletingSpiderId] = useState(null);
    const [deletingSpiderName, setDeletingSpiderName] = useState("");
    const [openEditModal, setOpenEditModal] = useState(false);
    const [editingSpider, setEditingSpider] = useState(null);

    const [googleSuggestEnabled, setGoogleSuggestEnabled] = useState(false);

    // Функция для обновления настроек google_suggest
    const updateGoogleSuggestSetting = async (isEnabled) => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/settings/scrapy`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ google_suggest: isEnabled })
            });

            if (!response.ok) {
                throw new Error('Ошибка при обновлении настроек google_suggest');
            }
        } catch (error) {
            console.error('Ошибка запроса: ', error);
        }
    };

    // Обработчик изменения Switch
    const handleGoogleSuggestSwitchChange = (event) => {
        setGoogleSuggestEnabled(event.target.checked);
        updateGoogleSuggestSetting(event.target.checked);
    };


    const [newSpider, setNewSpider] = useState({
        name: '',
        domain: '',
        spider_name: '',
        streams: 10,
    });
    const [errors, setErrors] = useState({
        name: '',
        domain: '',
        spider_name: '',
    });

    const handleOpenEditModal = (spider) => {
        setEditingSpider(spider);
        setOpenEditModal(true);
    };
    const handleSaveChanges = async (event) => {
        event.preventDefault();
        if (!editingSpider) return;

        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/settings/scrapy/spider/${editingSpider._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(editingSpider)
            });

            if (response.ok) {
                const data = await response.json();
                setSpiders(data.spiders); // Обновляем список пауков
                setOpenEditModal(false);
            } else {
                console.error('Ошибка при редактировании паука');
            }
        } catch (error) {
            console.error('Ошибка запроса: ', error);
        }
    };


    // Функция для открытия модального окна подтверждения удаления
    const handleOpenConfirmDelete = (spiderId,spiderName) => {

        setDeletingSpiderId(spiderId);
        setDeletingSpiderName(spiderName)
        setOpenConfirmDelete(true);
    };

    // Функция для закрытия модального окна подтверждения удаления
    const handleCloseConfirmDelete = () => {
        setOpenConfirmDelete(false);
        setDeletingSpiderId(null);
        setDeletingSpiderName("")
    };

    // Функция для удаления паука
    const handleDeleteSpider = async () => {
        if (!deletingSpiderId) return;

        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/settings/scrapy/spider/${deletingSpiderId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.ok) {
                const data = await response.json();
                setSpiders(data.spiders); // Обновляем список пауков
                handleCloseConfirmDelete();
            } else {
                console.error('Ошибка при удалении паука');
            }
        } catch (error) {
            console.error('Ошибка запроса: ', error);
        }
    };

    // Обработчик изменений в полях формы
    // Функция для обработки изменений в полях формы
    const handleInputChange = (event, newValue) => {
        // Для Autocomplete обрабатываем newValue, иначе event.target.value
        if(newValue !== undefined) {
            setNewSpider({ ...newSpider, spider_name: newValue });
        } else {
            setNewSpider({ ...newSpider, [event.target.name]: event.target.value });
        }
    };

    const validateForm = () => {
        let hasError = false;
        let errorMessages = { name: '', domain: '', spider_name: '' };

        if (newSpider.name.length < 3) {
            errorMessages.name = 'Поле должно содержать не меньше 3 символов';
            hasError = true;
        }

        if (!/^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(newSpider.domain)) {
            errorMessages.domain = 'Поле должно содержать корректное доменное имя без http и www';
            hasError = true;
        }

        if (!newSpider.spider_name) {
            errorMessages.spider_name = 'Выберите скраппер';
            hasError = true;
        }

        setErrors(errorMessages);
        return !hasError;
    };

    // Обработчик отправки формы
    const handleSubmit = async (event) => {
        event.preventDefault();
        const isValid = validateForm();
        if (!isValid) return;
        const token = localStorage.getItem('token');

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/settings/scrapy/spider`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ spider: newSpider })
            });

            if (response.ok) {
                const data = await response.json();
                // Обновляем состояние пауков после добавления
                setSpiders(data.spiders);
                handleCloseAddModal();
            } else {
                // Обработка ошибки
                console.error('Ошибка при добавлении паука');
                const responseData = await response.json();
                if (responseData.errorType === 'nameExists') {
                    setErrors(prevErrors => ({ ...prevErrors, name: 'Паук с таким именем уже существует' }));
                } else if (responseData.errorType === 'domainExists') {
                    setErrors(prevErrors => ({ ...prevErrors, domain: 'Паук с таким доменом уже существует' }));
                } else {
                    // Обработка других ошибок
                }
            }
        } catch (error) {
            console.error('Ошибка запроса: ', error);
        }
    };


    const handleOpenAddModal = () => {
        setOpenAddModal(true);
    };

    const handleCloseAddModal = () => {
        setOpenAddModal(false);
        setNewSpider({
            name: '',
            domain: '',
            spider_name: '',
            streams: 10,
        })
        setErrors({
            name: '',
            domain: '',
            spider_name: '',
        });
    };

    useEffect(() => {
        const fetchScrapySettings = async () => {
            const token = localStorage.getItem('token');
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/settings/scrapy`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();

            if (response.ok) {
                setSpiders(data.setting.spiders);
                setSpidersList(data.spiders_list);
                setGoogleSuggestEnabled(data.setting.google_suggest);
            } else {
                // Обработка ошибки, если запрос не удался
                console.error('Ошибка при получении настроек Scrapy:', data.message);
            }
            setIsLoading(false)
        };

        fetchScrapySettings();

    }, []);

    return (
        <LayoutDashboard>
            <Box
                sx={{
                    display: 'flex',
                    mb: 1,
                    gap: 1,
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: { xs: 'start', sm: 'center' },
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                }}
            >
                <Typography level="h2" component="h1">
                    Настройки пауков парсинга
                </Typography>

                <Button
                    color="primary"
                    size="sm"
                    onClick={handleOpenAddModal} // Добавляем обработчик нажатия
                >
                    Добавить
                </Button>

            </Box>
            {/* Содержимое dashboard */}


            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 2,
                    flexWrap: 'wrap'
                }}
            >
                <Sheet>
                    <Skeleton variant="overlay" loading={isLoading}>
                        <Typography
                            component="label"
                            endDecorator={<Switch checked={googleSuggestEnabled} onChange={handleGoogleSuggestSwitchChange}/>}
                        >
                            Подскаки Google
                        </Typography>
                    </Skeleton>
                </Sheet>

                <Sheet>
                    <Skeleton variant="overlay" loading={isLoading}>
                    <Typography level="h3">
                         Пауки
                    </Typography>
                    </Skeleton>

                    <Table aria-label="basic table" stickyHeader={false} stripe="odd">
                            <thead>
                            <tr>
                                <th style={{ width: '10%' }}>ID</th>
                                <th>Имя</th>
                                <th>Домен</th>
                                <th>Паук</th>
                                <th>Потоков</th>
                                <th>Дейстия</th>
                            </tr>
                            </thead>
                            <tbody>
                            {spiders.map((spider, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{spider.name}</td>
                                    <td>{spider.domain}</td>
                                    <td>{spider.spider_name}</td>
                                    <td>{spider.streams}</td>
                                    <td>
                                        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', alignItems: 'center' }}>
                                            <IconButton
                                                variant="solid"
                                                size="sm"
                                                color="primary"
                                                onClick={() => handleOpenEditModal(spider)}
                                            >
                                                <EditIcon/>
                                            </IconButton>

                                            <IconButton
                                                color="danger"
                                                variant="solid"
                                                size="sm"
                                                onClick={() => handleOpenConfirmDelete(spider._id,spider.name)}
                                            >
                                                <DeleteForeverIcon/>
                                            </IconButton>
                                        </Box>
                                    </td>
                                </tr>
                            ))}

                            </tbody>
                    </Table>
                </Sheet>
            </Box>


            <Modal open={openAddModal} onClose={handleCloseAddModal}>
                <ModalDialog>
                    <form onSubmit={handleSubmit}>
                        <DialogTitle>Добавить нового паука</DialogTitle>
                        <DialogContent>Fill in the information of the spider.</DialogContent>
                        <Stack spacing={2}>
                            <FormControl error={!!errors.name}>
                                <FormLabel>Имя</FormLabel>
                                <Input autoFocus name="name" value={newSpider.name} onChange={handleInputChange} />
                                {errors.name && <FormHelperText><InfoOutlined/> {errors.name}</FormHelperText>}
                            </FormControl>

                            <FormControl error={!!errors.domain}>
                                <FormLabel>Домен</FormLabel>
                                <Input name="domain" value={newSpider.domain} onChange={handleInputChange} />
                                {errors.domain && <FormHelperText><InfoOutlined/> {errors.domain}</FormHelperText>}
                            </FormControl>

                            <FormControl error={!!errors.spider_name}>
                                <FormLabel>Имя скрапера</FormLabel>
                                <Autocomplete
                                    options={spidersList}
                                    onChange={(event, newValue) => handleInputChange(event, newValue)}
                                    value={newSpider.spider_name}
                                />
                                {errors.spider_name && <FormHelperText><InfoOutlined/> {errors.spider_name}</FormHelperText>}
                            </FormControl>

                            <FormControl>
                                <FormLabel>Потоков</FormLabel>
                                <Input
                                       slotProps={{
                                           input: {
                                               min: 1,
                                           },
                                       }}
                                       required
                                       type="number"
                                       name="streams"
                                       defaultValue={10}
                                       onChange={handleInputChange}
                                />
                            </FormControl>
                            <DialogActions>
                                <Button color="neutral" onClick={handleCloseAddModal}>Отмена</Button>
                                <Button type="submit">Добавить</Button>
                            </DialogActions>

                        </Stack>
                    </form>
                </ModalDialog>
            </Modal>

            <Modal open={openConfirmDelete} onClose={handleCloseConfirmDelete}>
                <ModalDialog variant="outlined" role="alertdialog">
                    <DialogTitle>
                        <WarningRoundedIcon />
                        Подтверждение
                    </DialogTitle>
                    <DialogContent>
                        Вы действительно хотите удалить паука "{deletingSpiderName}"?
                    </DialogContent>
                    <DialogActions>
                        <Button variant="solid" color="danger" onClick={handleDeleteSpider}>
                            Удалить
                        </Button>
                        <Button variant="plain" color="neutral" onClick={handleCloseConfirmDelete}>
                            Отмена
                        </Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>



            <Modal open={openEditModal} onClose={() => setOpenEditModal(false)}>
                <ModalDialog>
                    <form onSubmit={handleSaveChanges}>
                        <DialogTitle>Изменение настроек</DialogTitle>
                        <DialogContent>Fill in the information of the spider.</DialogContent>
                        <Stack spacing={2}>
                            <FormControl>
                                <FormLabel>Имя</FormLabel>
                                <Input
                                    autoFocus
                                    name="name"
                                    value={editingSpider?.name || ''}
                                    onChange={(e) => setEditingSpider({ ...editingSpider, name: e.target.value })}
                                    disabled
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Домен</FormLabel>
                                <Input
                                    name="domain"
                                    value={editingSpider?.domain || ''}
                                    onChange={(e) => setEditingSpider({ ...editingSpider, domain: e.target.value })}
                                    disabled
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Имя скрапера</FormLabel>
                                <Input
                                    name="spider_name"
                                    value={editingSpider?.spider_name || ''}
                                    disabled
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Потоков</FormLabel>
                                <Input
                                    required
                                    type="number"
                                    name="streams"
                                    value={editingSpider?.streams || 1}
                                    onChange={(e) => setEditingSpider({ ...editingSpider, streams: e.target.value })}
                                />
                            </FormControl>
                            <DialogActions>
                                <Button color="neutral" onClick={() => setOpenEditModal(false)}>Отмена</Button>
                                <Button type="submit">Сохранить</Button>
                            </DialogActions>
                        </Stack>
                    </form>
                </ModalDialog>
            </Modal>



        </LayoutDashboard>
    );
};

export default ScrapySettings;
