import React from 'react';
import LayoutDashboard from "../../components/LayoutDashboard";

const IndexUsers = () => {
    return (
        <LayoutDashboard>
            <h1>Index Users</h1>
            {/* Содержимое dashboard */}
        </LayoutDashboard>
    );
};

export default IndexUsers;
