import React, { useState, useEffect } from 'react';
import { useColorScheme } from '@mui/joy/styles';
import IconButton from '@mui/joy/IconButton';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeIcon from '@mui/icons-material/LightMode';

export default function ColorSchemeToggle(props) {
    const { onClick, sx, ...other } = props;
    const { mode, setMode } = useColorScheme();
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);
    }, []);

    if (!mounted) {
        return <IconButton size="sm" variant="outlined" color="neutral" {...other} sx={sx} disabled />;
    }

    return (
        <IconButton
            id="toggle-mode"
            size="sm"
            variant="outlined"
            color="neutral"
            {...other}
            onClick={(event) => {
                setMode(mode === 'light' ? 'dark' : 'light');
                if (onClick) onClick(event);
            }}
            sx={[
                {
                    '& > *:first-of-type': { display: mode === 'dark' ? 'none' : 'initial' },
                    '& > *:last-child': { display: mode === 'light' ? 'none' : 'initial' },
                },
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
        >
            <DarkModeRoundedIcon />
            <LightModeIcon />
        </IconButton>
    );
}
