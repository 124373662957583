// routes.js
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Help from './pages/Help';
import Settings from './pages/settings/Settings';
import IndexUsers from "./pages/users/IndexUsers";
import Invitations from "./pages/users/Invitations";
import Users from "./pages/users/Users";
import MyProfile from "./pages/users/MyProfile";
import AtWork from "./pages/atwork/AtWork";
import WorkAdd from "./pages/atwork/add";
import ScrapySettings from "./pages/settings/ScrapySettings";
import GptSettings from "./pages/settings/GptSettings";
import WpSettings from "./pages/settings/WpSettings";


const routes = [
    { path: "/", element: Home, protected: false, title: "Главная"},
    { path: "/login", element: Login, protected: false, title: "Вход"},
    { path: "/dashboard", element: Dashboard, protected: true, title: "Dashboard" },

    { path: "/atwork", element: AtWork, protected: true, title: "В работе" },
    { path: "/atwork/add", element: WorkAdd, protected: true, title: "Добавить статьи" },

    { path: "/help", element: Help, protected: true, title: "Помощь" },

    { path: "/settings/global", element: Settings, protected: true, title: "Настройки" },
    { path: "/settings/scrapy", element: ScrapySettings, protected: true, title: "Парсинг" },
    { path: "/settings/gpt", element: GptSettings, protected: true, title: "Чат GPT" },
    { path: "/settings/wp", element: WpSettings, protected: true, title: "WordPress настройки" },

    { path: "/users", element: IndexUsers, protected: true, title: "Пользователи" },
    { path: "/users/invitations", element: Invitations, protected: true, title: "Приглашения" },
    { path: "/users/all", element: Users, protected: true, title: "Все пользователи"},
    { path: "/users/myprofile", element: MyProfile, protected: true, title: "Мой профайл" }
];

export default routes;
