import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isAuthenticating, setIsAuthenticating] = useState(true);

    const validateToken = async () => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/validateToken`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
                const data = await response.json();
                if (response.ok) {
                    setUser({
                        username: data.username,
                        email: data.email,
                        name: data.name
                    });
                }
            } catch (error) {
                console.error('Ошибка при проверке токена', error);
            }
        }
    };

    useEffect(() => {
        validateToken().then(() => {
            setIsAuthenticating(false);
        });
    }, []);


    const login = (userData, token) => {
        localStorage.setItem('token', token);
        setUser({
            username: userData.username,
            email: userData.email,
            name: userData.name
        });

        // ... остальная логика входа ...
    };

    const logout = () => {
        localStorage.removeItem('token'); // Удаление токена из localStorage
        setUser(null); // Очистка данных пользователя из состояния
    };

    return (
        <AuthContext.Provider value={{ user, isAuthenticating, login, logout }}>
            {!isAuthenticating && children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
